import { MouseEvent } from "react";
import MultiSelectOption from "./MultiSelectOption";
import styles from "./MultiSelectOptions.module.scss";
import MultiSelectOptionLoading from "./MultiSelectOptionDefault";
type MultiSelectOptionsProps<T> = {
  items: T[];
  show: boolean;
  loading: boolean;
  listIsEmpty: boolean;
  add: (item: T) => void;
  selectedIndex: number;
  getOptionLabel: (item: T) => string;
};

const MultiSelectOptions = <T extends { _id: string }>({
  items,
  show,
  add,
  selectedIndex,
  listIsEmpty,
  loading,
  getOptionLabel,
}: MultiSelectOptionsProps<T>) => {
  if (!show) return null;

  const handleListItemClick = (e: MouseEvent<HTMLLIElement>, item: T) => {
    add(item);
  };

  return (
    <div
      className={styles.optionsContainer}
      onMouseDown={(e) => e.preventDefault()}
    >
      <ul className={styles.list}>
        {(loading || listIsEmpty) && (
          <MultiSelectOptionLoading isEmpty={listIsEmpty} loading={loading} />
        )}
        {items.map((item, index) => (
          <MultiSelectOption<T>
            key={item._id}
            item={item}
            isFocused={selectedIndex === index}
            getOptionLabel={getOptionLabel}
            handleListItemClick={handleListItemClick}
          />
        ))}
      </ul>
    </div>
  );
};

export default MultiSelectOptions;
