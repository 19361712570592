import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "react-toastify";

import {
  getDocuments,
  getFundingDocuments,
  uploadDocument,
  uploadFundedDocument,
} from "@Http/documents";

import { AddDocumentRequest, Document } from "@Types/documents";
import { HttpQuery } from "@Types/http";
import { MutationError } from "@Types/index";

export const useCreateDocument = (onSuccess: () => void = () => undefined) => {
  const [progress, setProgress] = useState(0);
  const mutation = useMutation<Document, MutationError, AddDocumentRequest>(
    uploadDocument(setProgress),
    {
      onSuccess: () => {
        toast.success("Successfully uploaded a document.", {
          position: "top-center",
        });
        onSuccess();
      },
    }
  );
  return {
    createDocument: mutation.mutateAsync,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
    progress,
  };
};

export const useCreateFundedDocument = (
  onSuccess: () => void = () => undefined
) => {
  const [progress, setProgress] = useState(0);
  const mutation = useMutation<Document, MutationError, AddDocumentRequest>(
    uploadFundedDocument(setProgress),
    {
      onSuccess: () => {
        toast.success("Successfully uploaded a document.", {
          position: "top-center",
        });
        onSuccess();
      },
    }
  );
  return {
    createDocument: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
    progress,
  };
};

export const useGetDocuments = (
  queryKeyProp: string,
  query?: HttpQuery,
  enabled = true
) => {
  const queryKey = ["documents", queryKeyProp, query];

  const { data, isLoading, error, refetch } = useQuery<
    Document[],
    MutationError,
    Document[]
  >({
    queryKey: queryKey,
    queryFn: getDocuments(query),
    enabled,
  });

  return {
    data,
    isLoading,
    error,
    refetch,
  };
};

export const useGetFundingDocuments = (
  queryKeyProp: string,
  query?: HttpQuery
) => {
  const queryKey = ["funded_documents", queryKeyProp, query];

  const { data, isLoading, error, refetch } = useQuery<
    Document[],
    MutationError,
    Document[]
  >({
    queryKey: queryKey,
    queryFn: getFundingDocuments(query),
  });

  return {
    data,
    isLoading,
    error,
    refetch,
  };
};
