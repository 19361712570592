import { auth } from "@Firebase/firebase";
import { DefaultStipulation } from "@Types/default_stipulations";
import { HttpQuery } from "@Types/http";
import axios from "axios";

const getDefaultStipulations = (
  query?: HttpQuery
): (() => Promise<DefaultStipulation[]>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/dealership-api/v1/default_stipulation/`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          query:
            query && Object.keys(query)?.length
              ? JSON.stringify(query)
              : undefined,
        },
      }
    );

    return response.data.result?.docs ?? [];
  };
};
export default getDefaultStipulations;
