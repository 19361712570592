import { MouseEvent, useEffect, useRef } from "react";
import styles from "./MultiSelectOptions.module.scss";

type MultiSelectOptionProps<T> = {
  item: T;
  getOptionLabel: (item: T) => string;
  handleListItemClick: (e: MouseEvent<HTMLLIElement>, item: T) => void;
  isFocused: boolean;
};

const MultiSelectOption = <T extends { _id: string }>({
  item,
  handleListItemClick,
  getOptionLabel,
  isFocused,
}: MultiSelectOptionProps<T>) => {
  // Ref used to access individual item
  const ref = useRef<HTMLLIElement>(null);

  // As we are doing manual focus management
  // We need to keep selected item into the view when using up down arrow keys
  // Because of overflow in the suggestions list
  useEffect(() => {
    if (isFocused && ref.current) {
      ref.current.scrollIntoView({ behavior: "auto", block: "nearest" });
    }
  }, [isFocused]);

  return (
    <li
      ref={ref}
      key={item._id}
      onClick={(e) => handleListItemClick(e, item)}
      className={styles.option}
    >
      <span>{getOptionLabel(item)}</span>
    </li>
  );
};

export default MultiSelectOption;
