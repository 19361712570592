import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";

import {
  getTeam,
  getTeams,
  createTeam,
  deleteTeam,
  updateTeam,
} from "@Http/teams";
import { HttpQuery } from "@Types/http";
import { MutationError } from "@Types/index";
import { CreateTeamRequest, Team } from "@Types/teams";

const useGetTeams = (query?: HttpQuery) => {
  const queryKey = ["teams", query ?? []];

  const { data, isLoading, isFetching, error } = useQuery<
    Team[],
    MutationError,
    Team[]
  >({
    queryKey: queryKey,
    queryFn: getTeams(query),
  });

  return {
    data,
    isFetching,
    isLoading,
    error,
  };
};

export const useGetTeam = (id: string) => {
  const queryKey = [`team-${id}`];

  const {
    data: team,
    isLoading,
    error,
    isSuccess,
  } = useQuery({
    queryKey: queryKey,
    queryFn: getTeam(id),
  });

  return {
    isSuccess,
    team,
    isLoading,
    error,
  };
};

export const useDeleteTeam = () => {
  const navigate = useNavigate();

  const mutation = useMutation<Team, MutationError, Team>(deleteTeam(), {
    onSuccess: () => {
      navigate("/teams", { replace: true });
      toast.success("Successfully deleted a team.", {
        position: "top-center",
      });
    },
  });
  return {
    deleteTeam: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};

export const useUpdateTeam = () => {
  const mutation = useMutation<Team, MutationError, Team>(updateTeam(), {
    onSuccess: () => {
      toast.success("Successfully updated an team.", {
        position: "top-center",
      });
    },
  });
  return {
    updateTeam: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};

export const useCreateTeam = () => {
  const navigate = useNavigate();
  const mutation = useMutation<Team, MutationError, CreateTeamRequest>(
    createTeam(),
    {
      onSuccess: (data) => {
        toast.success("Successfully created an team.", {
          position: "top-center",
        });
        navigate(`/teams/${data._id}`, { replace: true });
      },
    }
  );
  return {
    createTeam: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};

export default useGetTeams;
