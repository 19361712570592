import styles from "./checkbox.module.scss";

interface TextFieldProps {
  placeholder: string;
  value?: string | number;
  containerStyle?: React.CSSProperties;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  id?: string;
  name?: string;
  disabled?: boolean;
  required?: boolean;
  inputStyle?: React.CSSProperties;
  className?: string;
  checked?: boolean;
  showPlaceHolderTop?: boolean;
  labelStyle?: string;
}

const Checkbox = ({
  labelStyle,
  checked,
  placeholder,
  onChange,
  inputStyle,
  containerStyle,
  id,
  name,
  required = false,
  disabled = false,
  showPlaceHolderTop = true,
  className = "",
}: TextFieldProps) => {
  return (
    <div
      className={styles.inputContainer + ` ${className}`}
      style={
        containerStyle
          ? containerStyle?.height
            ? containerStyle
            : { ...containerStyle }
          : {}
      }
    >
      <input
        checked={checked}
        style={inputStyle}
        disabled={disabled}
        id={id}
        data-testid={id}
        name={name}
        required={required}
        onChange={(e) => {
          e.stopPropagation();
          onChange?.(e);
        }}
        type="checkbox"
        className={styles.input}
        placeholder={`${placeholder}${required ? " *" : ""}`}
      />
      {showPlaceHolderTop && (
        <label className={`${styles.label} ${labelStyle ?? ""}`} htmlFor={id}>
          {`${placeholder}${required ? " *" : ""}`}
        </label>
      )}
    </div>
  );
};
export default Checkbox;
