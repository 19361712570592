export type Stipulation = {
  data: FormState;
  _id: string;
  createdAt: string | Date;
  updatedAt: string | Date;
};
export type FormState = {
  dealId: string;
  info: Info;
  defaultStipulationId?: string;
};
export enum StipulationStatus {
  PENDING = "pending",
  AWAITING_UPLOAD = "awaiting upload",
  APPROVED = "approved",
  REJECTED = "rejected",
}

export type Info = {
  id: string;
  description: string;
  url?: string | null;
  rejectedUrls?: string[];
  status: StipulationStatus;
  createdBy: "dealer" | "manager" | "system";
  notificationSendDate: string;
  canBeUploadedBy: ("applicant" | "coApplicant" | "dealer")[];
  owner: "applicant" | "coApplicant";
  uploadedBy?: "applicant" | "coApplicant" | "dealer" | "manager";
  uploadedAt?: string;
  user: {
    firstName: string | null;
    lastName: string | null;
    email: string | null;
  };
};

export type UpdateStipulationRequest = Stipulation & {
  metadata: object;
  fileName: string;
};

export enum StipulationSystemNameEnum {
  DRIVER_LICENSE_FRONT_APPLICANT = "driver_license_front_applicant",
  DRIVER_LICENSE_BACK_APPLICANT = "driver_license_back_applicant",
  DRIVER_LICENSE_FRONT_CO_APPLICANT = "driver_license_front_co_applicant",
  DRIVER_LICENSE_BACK_CO_APPLICANT = "driver_license_back_co_applicant",
}
