import { useRef, useState } from "react";

import MultiSelectOptions from "./MultiSelectOptions";
import useMultiSelect from "./useMultiSelect";
import MultiSelectChip from "./MultiSelectChip";
import styles from "./MultiSelect.module.scss";
import { IoIosArrowDown, IoIosArrowUp, IoMdClose } from "react-icons/io";
import IconButton from "@Components/IconButton";
import useClickOutside from "@Hooks/useClickOutside";

const MultiSelect = <T extends { _id: string }>({
  placeholder,
  options,
  value,
  onChange,
  loading = false,
  required = false,
  getOptionLabel,
  id,
  name,
  showPlaceHolderTop = true,
  getOptionSelected,
}: {
  value: T[];
  loading?: boolean;
  name?: string;
  id?: string;
  options: T[];
  required?: boolean;
  showPlaceHolderTop?: boolean;
  placeholder: string;
  onChange: (selectedItems: T[]) => void;
  getOptionSelected: (x: T, y: T) => boolean;
  getOptionLabel: (item: T) => string;
}) => {
  const {
    showList,
    setShowList,
    remove,
    add,
    lastHightlight,
    searchValue,
    handleKeyDown,
    filteredItemsList,
    handleOnChange,
    selectedIndex,
  } = useMultiSelect(
    options,
    value,
    getOptionLabel,
    getOptionSelected,
    onChange
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [showRemoveAll, setShowRemoveAll] = useState(false);
  const [containerFocused, setContainerFocused] = useState(false);
  useClickOutside(containerRef, () => () => {
    setShowRemoveAll(false);
    setShowList(false);
    setContainerFocused(false);
  });

  const handleContainerClick = () => {
    if (!showList) {
      setShowList(true);
      inputRef.current?.focus();
    }
  };
  return (
    <div
      className={styles.container}
      ref={containerRef}
      onMouseEnter={() => {
        setShowRemoveAll(true);
      }}
      onFocus={() => setContainerFocused(true)}
      onMouseLeave={
        containerFocused ? undefined : () => setShowRemoveAll(false)
      }
    >
      <div className={styles.inputContainer} onClick={handleContainerClick}>
        {value.map((item, index) => (
          <MultiSelectChip<T>
            key={index}
            item={item}
            getOptionLabel={getOptionLabel}
            remove={remove}
            shouldHighlight={lastHightlight && index === value.length - 1}
          />
        ))}
        {value?.length > 0 && showPlaceHolderTop && (
          <label className={styles.label} htmlFor={name}>
            {`${placeholder}${required ? " *" : ""}`}
          </label>
        )}
        <input
          ref={inputRef}
          type="text"
          id={id}
          required={required ? value?.length < 1 : false}
          name={name}
          className={styles.input}
          placeholder={
            showPlaceHolderTop && value?.length > 0
              ? undefined
              : `${placeholder} ${required ? "*" : ""}`
          }
          value={searchValue}
          onChange={handleOnChange}
          onKeyDown={handleKeyDown}
        />

        <div className={styles.actionIcons}>
          {showRemoveAll && value.length > 0 && (
            <IconButton
              style={{ padding: 0 }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onChange([]);
              }}
            >
              <IoMdClose size={15} />
            </IconButton>
          )}
          <IconButton
            style={{ padding: 0 }}
            onClick={(e) => {
              e.preventDefault();
              setShowList(!showList);
            }}
          >
            {showList ? (
              <IoIosArrowUp size={15} />
            ) : (
              <IoIosArrowDown size={15} />
            )}
          </IconButton>
        </div>
      </div>

      <MultiSelectOptions<T>
        getOptionLabel={getOptionLabel}
        loading={loading}
        listIsEmpty={filteredItemsList.length === 0}
        items={filteredItemsList}
        show={showList}
        add={add}
        selectedIndex={selectedIndex}
      />
    </div>
  );
};

export default MultiSelect;
