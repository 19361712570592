import { MouseEvent } from "react";
import styles from "./MultiSelectChip.module.scss";
type MultiSelectChipProp<T> = {
  item: T;
  getOptionLabel: (item: T) => string;
  remove: (e: MouseEvent<HTMLButtonElement>, item: T) => void;
  shouldHighlight: boolean;
};

const MultiSelectChip = <T extends { _id: string }>({
  item,
  getOptionLabel,
  remove,
  shouldHighlight,
}: MultiSelectChipProp<T>) => {
  return (
    <div
      key={item._id}
      className={[
        styles.chip,
        shouldHighlight ? styles.chipHighlight : styles.chipNormal,
      ].join(" ")}
    >
      <div>
        <span className={styles.label}>{getOptionLabel(item)}</span>
      </div>
      <button className={styles.button} onClick={(e) => remove(e, item)}>
        x
      </button>
    </div>
  );
};

export default MultiSelectChip;
