import mixpanel from "mixpanel-browser";
import { Dispatch, SetStateAction } from "react";

import Grid from "@Components/Grid";
import Loading from "@Components/Loading";
import Radio from "@Components/Radio";
import useCurrentUser from "@Hooks/useCurrentUser";
import { useCreateDocument } from "@Hooks/useDocuments";
import { Document, DriverLicenseType } from "@Types/documents";
import { StipulationSystemNameEnum } from "@Types/stipulations";

import DocumentsDropzone from "@Pages/Applications/Application/Documents/DocumentsDropzone/DocumentsDropzone";
import styles from "./styles.module.scss";

const RenderDropZone = ({
  dealId,
  loading,
  type,
  document,
  handleCreateDocuments,
}: {
  dealId: string;
  loading: boolean;
  type: "front" | "back";
  document: Document | undefined;
  handleCreateDocuments: (
    type: "front" | "back"
  ) => (file: File) => Promise<void>;
}) => {
  return (
    <Grid xs={5} className={styles.uploadDriverLicenseContainer}>
      {document ? (
        <div className={styles.uploadedDocument}>
          <p>
            Uploaded file:{" "}
            <a
              className={styles.scanText}
              href={document.data.info.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {document.data.info.name}
            </a>
          </p>
          {["image/jpeg", "image/png"].includes(
            document?.data.info.metadata?.contentType as string
          ) && <img src={document.data.info.url} alt="Driver's license" />}
          You can reupload the file, if needed.
        </div>
      ) : (
        <div className={styles.uploadedDocument}>
          <p>{`Upload the ${type} of the driver license.`}</p>
          Ensure the details are visible and the image is clear.
        </div>
      )}
      {loading ? (
        <Loading />
      ) : (
        <div className={styles.drop}>
          <DocumentsDropzone
            section="driver-license"
            dealId={dealId}
            fullWidth
            buttonText={document ? "Upload again" : undefined}
            showArrow={!document}
            handleCreate={handleCreateDocuments(type)}
          />
        </div>
      )}
    </Grid>
  );
};

export function getLatestDocumentByName(
  documents: Document[],
  side: string
): Document | undefined {
  const filteredDocuments = documents.filter(
    (doc) => doc.data.info?.driverLicenseData?.side === side
  );

  if (filteredDocuments.length === 0) {
    return undefined;
  }

  const sortedDocuments = filteredDocuments.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );

  return sortedDocuments[0];
}

const UploadDriverLicense = ({
  dealId,
  documents,
  setEnabled,
  creditType,
  driverLicenseType,
  setDriverLicenseType,
}: {
  dealId: string;
  documents?: Document[];
  creditType: "individual" | "joint";
  setEnabled: Dispatch<SetStateAction<boolean>>;
  driverLicenseType: DriverLicenseType;
  setDriverLicenseType: Dispatch<SetStateAction<DriverLicenseType>>;
}) => {
  const user = useCurrentUser();

  const { createDocument: uploadDriverLicenseFront, loading: loadingFront } =
    useCreateDocument();
  const { createDocument: uploadDriverLicenseBack, loading: loadingBack } =
    useCreateDocument();
  const latestDriverLicenseFront = getLatestDocumentByName(
    documents ?? [],
    "front"
  );
  const latestDriverLicenseBack = getLatestDocumentByName(
    documents ?? [],
    "back"
  );

  const handleCreateDocuments =
    (type: "front" | "back") => async (file: File) => {
      const uploadFunction =
        type === "front" ? uploadDriverLicenseFront : uploadDriverLicenseBack;
      if (file) {
        setEnabled(false);
        await uploadFunction({
          data: {
            dealId: null,
            incompleteDealId: dealId,
            systemName:
              driverLicenseType === "Applicant"
                ? type === "front"
                  ? StipulationSystemNameEnum.DRIVER_LICENSE_FRONT_APPLICANT
                  : StipulationSystemNameEnum.DRIVER_LICENSE_BACK_APPLICANT
                : driverLicenseType === "CoApplicant"
                ? type === "front"
                  ? StipulationSystemNameEnum.DRIVER_LICENSE_FRONT_CO_APPLICANT
                  : StipulationSystemNameEnum.DRIVER_LICENSE_BACK_CO_APPLICANT
                : undefined,
            info: {
              driverLicenseData: {
                applicantType: driverLicenseType,
                side: type,
              },
              name: `${driverLicenseType} Driver's License ${type}`,
            },
          },
          file,
        });
        setEnabled(true);
        mixpanel.track("Driver License uploaded", {
          email: user?.data?.info?.email,
          dealershipName: user?.data?.dealership?.data?.info?.name,
        });
      }
    };

  return (
    <Grid xs={12} container className={styles.root}>
      {creditType === "joint" && (
        <Radio
          id="driverLicenseType"
          group="driverLicenseType"
          required
          possibleValues={[
            {
              value: "Applicant",
              label: "Applicant",
            },
            {
              value: "CoApplicant",
              label: "CoApplicant",
            },
          ]}
          onChange={(e) => {
            switch (e.target.value) {
              case "Applicant":
              case "CoApplicant":
                return setDriverLicenseType(e.target.value);

              default:
                return setDriverLicenseType("Applicant");
            }
          }}
          selectedValue={driverLicenseType}
        />
      )}
      <Grid xs={12} container className={styles.scanContainer}>
        <RenderDropZone
          type="front"
          dealId={dealId}
          loading={loadingFront}
          document={latestDriverLicenseFront}
          handleCreateDocuments={handleCreateDocuments}
        />
        <RenderDropZone
          type="back"
          dealId={dealId}
          loading={loadingBack}
          document={latestDriverLicenseBack}
          handleCreateDocuments={handleCreateDocuments}
        />
      </Grid>
    </Grid>
  );
};

export default UploadDriverLicense;
