import { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import io from "socket.io-client";

import { useBrowserNotifications } from "@Hooks/useBrowserNotifications";
import { Applicant } from "@Types/applicants";
import { Channel } from "@Types/channels";
import { Deal } from "@Types/deal";
import { Document } from "@Types/documents";
import IncompleteDeal from "@Types/incomplete_application";
import { DealershipNotification } from "@Types/dealership_notifications";
import { PaginatedResult } from "@Types/http";
import { InfiniteData } from "@tanstack/react-query";
import { Lender } from "@Types/lenders";
import { Message } from "@Types/messages";
import { User } from "@Types/users";
import { CurrentUser, queryClient } from "../../App";
import { TitleIssue } from "@Types/title_issues";
import { DealershipTicket } from "@Types/dealership_tickets";
import SocketConnectionContext from "@Contexts/SocketConnectionContext";

type Payload = {
  action: "create" | "update";
  entity:
    | "deal"
    | "applicant"
    | "user"
    | "dealership_notification"
    | "lender"
    | "document"
    | "collateral"
    | "channel"
    | "message"
    | "incomplete_deal"
    | "dealership_ticket"
    | "title_issue";
  entry:
    | DealershipNotification
    | Deal
    | Applicant
    | Document
    | Lender
    | User
    | Channel
    | Message
    | DealershipTicket
    | IncompleteDeal
    | TitleIssue;
};

const getSocket = (
  currentUser: CurrentUser,
  showNotification: (title: string, options?: NotificationOptions) => void,
  setSocketConnected: React.Dispatch<React.SetStateAction<boolean>>
) => {
  return currentUser?.getIdToken().then(async (token) => {
    const socket = io(process.env.REACT_APP_SOCKET_URL + "/dealership", {
      auth: {
        token,
        firebaseName: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      },
      query: { connectedAt: new Date().getTime() },
    });
    socket.on("connect", () => {
      setSocketConnected(true);
      console.log("Connected!");
    });
    socket.on(`${currentUser?.email}_live_updates`, (payload: Payload) => {
      switch (payload.action) {
        case "create":
          switch (payload.entity) {
            case "dealership_notification": {
              const entry = payload.entry as DealershipNotification;
              queryClient.setQueriesData<
                InfiniteData<PaginatedResult<DealershipNotification[]>>
              >(["dealership_notifications"], (oldData) => {
                return {
                  pageParams: oldData?.pageParams,
                  pages: (oldData?.pages ?? [])?.map((page, index) => {
                    if (index === 0) {
                      return {
                        ...page,
                        docs: [entry, ...(page?.docs ?? [])],
                      };
                    } else {
                      return page;
                    }
                  }),
                } as InfiniteData<PaginatedResult<DealershipNotification[]>>;
              });
              break;
            }
            case "incomplete_deal": {
              const entry = payload.entry as IncompleteDeal;
              queryClient.setQueriesData<IncompleteDeal[]>(
                ["incomplete_applications"],
                (oldData) => {
                  if (oldData?.find((x) => x._id === entry._id)) return oldData;
                  return [entry, ...(oldData ?? [])];
                }
              );
              break;
            }
            case "channel": {
              const entry = payload.entry as Channel;
              queryClient.setQueriesData<Channel[]>(["channels"], (oldData) => {
                if (oldData?.find((x) => x._id === entry._id)) return oldData;
                return [entry, ...(oldData ?? [])];
              });
              break;
            }
            case "message": {
              const entry = payload.entry as Message;
              queryClient.setQueryData<Message[]>(
                [`messages-${entry.data.channelId}`, []],
                (oldData) => {
                  const foundIndex = (oldData ?? [])?.findIndex(
                    (oldEntry) =>
                      oldEntry?.data?.internalMessageId ===
                        entry?.data?.internalMessageId ||
                      oldEntry?._id === entry?._id
                  );
                  if (foundIndex >= 0) {
                    return oldData?.map((x, index) =>
                      index === foundIndex
                        ? {
                            ...entry,
                            data: {
                              ...entry.data,
                              info: {
                                ...entry.data.info,
                                attachments:
                                  x?.data?.info?.attachments ||
                                  entry?.data?.info?.attachments ||
                                  [],
                              },
                            },
                          }
                        : x
                    );
                  } else {
                    const currentChannelId =
                      window.localStorage.getItem("channelId");
                    const selectedChannelId = currentChannelId
                      ? JSON.parse(currentChannelId)
                      : "";
                    const chatNotificationsEnabled = JSON.parse(
                      window.localStorage.getItem(
                        "chatNotificationsEnabled"
                      ) as string
                    );
                    if (
                      selectedChannelId !== entry?.data?.channelId &&
                      chatNotificationsEnabled
                    )
                      showNotification(
                        `New message from: ${entry?.data?.chatMember?.data?.info?.names}.`
                      );
                    return [...(oldData ?? []), entry];
                  }
                }
              );

              queryClient.setQueriesData<Channel[]>(["channels"], (oldData) => {
                const channelIndex = (oldData ?? [])?.findIndex(
                  (x) => x._id === entry.data.channelId
                );
                if (channelIndex >= 0 && oldData) {
                  oldData.unshift(oldData.splice(channelIndex, 1)[0]);
                  return oldData.map((el) => el);
                }
                return oldData;
              });
              break;
            }
            default:
              break;
          }
          break;
        case "update":
          switch (payload.entity) {
            case "dealership_ticket": {
              const entry = payload.entry as DealershipTicket;
              queryClient.setQueriesData<DealershipTicket>(
                [`dealership-ticket-${entry._id}`],
                () => entry
              );
              queryClient.setQueriesData<DealershipTicket[]>(
                ["dealership_tickets"],
                (oldData) => {
                  return (oldData ?? [])?.map((oldDealershipTicket) =>
                    oldDealershipTicket._id === entry._id
                      ? entry
                      : oldDealershipTicket
                  );
                }
              );
              break;
            }
            case "title_issue": {
              const entry = payload.entry as TitleIssue;

              queryClient.setQueriesData<TitleIssue>(
                [`title-issue-${entry._id}`],
                () => entry
              );
              queryClient.setQueriesData<TitleIssue[]>(
                ["title_issues"],
                (oldData) => {
                  return (oldData ?? [])?.map((oldTitleIssue) =>
                    oldTitleIssue._id === entry._id ? entry : oldTitleIssue
                  );
                }
              );
              break;
            }
            case "dealership_notification": {
              const entry = payload.entry as DealershipNotification;

              queryClient.setQueriesData<
                InfiniteData<PaginatedResult<DealershipNotification[]>>
              >(
                ["dealership_notifications"],
                (oldData) =>
                  ({
                    pageParams: oldData?.pageParams ? oldData.pageParams : [],
                    pages:
                      oldData?.pages?.map((page) => ({
                        ...page,
                        docs: page.docs.map((d) =>
                          d._id === entry._id ? entry : d
                        ),
                      })) ?? [],
                  } as InfiniteData<PaginatedResult<DealershipNotification[]>>)
              );
              break;
            }

            case "incomplete_deal": {
              const entry = payload.entry as IncompleteDeal;
              queryClient.setQueriesData<IncompleteDeal>(
                ["incomplete_application", entry._id],
                () => {
                  return entry;
                }
              );
              queryClient.setQueriesData<IncompleteDeal[]>(
                ["incomplete_applications"],
                (oldData) => {
                  return (oldData ?? [])?.map((oldDeal) =>
                    oldDeal._id === entry._id ? entry : oldDeal
                  );
                }
              );
              break;
            }
            case "message": {
              const entry = payload.entry as Message;
              queryClient.setQueriesData<Message[]>(
                [`messages-${entry.data.channelId}`],
                (oldData) => {
                  const foundIndex = (oldData ?? [])?.findIndex(
                    (oldEntry) => oldEntry?._id === entry?._id
                  );
                  return oldData?.map((x, index) =>
                    index === foundIndex ? entry : x
                  );
                }
              );
              break;
            }
            case "deal": {
              const entry = payload.entry as Deal;
              queryClient.setQueriesData<Deal>(["deal", entry._id], () => {
                return entry;
              });
              queryClient.setQueriesData<Deal[]>(["deals"], (oldData) => {
                return (oldData ?? [])?.map((oldDeal) =>
                  oldDeal._id === entry._id ? entry : oldDeal
                );
              });
              break;
            }
            case "channel": {
              const entry = payload.entry as Channel;
              queryClient.setQueriesData<Channel[]>(["channels"], (oldData) => {
                const channelIndex = oldData?.findIndex(
                  (x) => x._id === entry._id
                );
                if (
                  oldData &&
                  channelIndex !== undefined &&
                  channelIndex > -1
                ) {
                  return oldData?.map((x, index) =>
                    index === channelIndex ? entry : x
                  );
                } else {
                  return [...(oldData ?? []), entry];
                }
              });
              break;
            }
            default:
              break;
          }
          break;

        default:
          break;
      }
    });
    socket.on("disconnect", () => {
      setSocketConnected(false);
      console.log("Socket disconnected");
      socket.close();
      setTimeout(() => {
        getSocket(currentUser, showNotification, setSocketConnected);
      }, 1000);
    });
    socket.on("error", () => {
      setSocketConnected(false);
      socket.disconnect();
      console.log("Socket connection errror");
      setTimeout(() => {
        getSocket(currentUser, showNotification, setSocketConnected);
      }, 1000);
    });
    return socket;
  });
};

const SocketConnection = ({ currentUser }: { currentUser: CurrentUser }) => {
  const { showNotification } = useBrowserNotifications();
  const { setSocketConnected } = useContext(SocketConnectionContext);
  useEffect(() => {
    const socket = getSocket(currentUser, showNotification, setSocketConnected);

    return () => {
      socket?.then((resolvedSocket) => resolvedSocket.close());
    };
  }, [currentUser]);
  return <Outlet />;
};

export default SocketConnection;
