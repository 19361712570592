import Button from "@Components/Button";
import Dialog from "@Components/Dialog";
import { DialogActions } from "@Components/Dialog/DialogActions";
import { DialogContent } from "@Components/Dialog/DialogContent";
import Checkbox from "@Components/Inputs/Checkbox";
import { useStickyState } from "@Hooks/useStickyState";
import { useState } from "react";
import { FaQuestionCircle } from "react-icons/fa";
import styles from "./WhatsNew.module.scss";

const WhatsNew = () => {
  const [isSeenRelease, setIsSeenRelease] = useStickyState(
    false,
    // change this name when releasing something new.
    "release-02/25/2025"
  );
  const [show, setShow] = useState(!isSeenRelease);
  return (
    <>
      <button
        className={styles.button}
        onClick={() => {
          setShow(!show);
        }}
      >
        <span>
          <FaQuestionCircle />
        </span>

        {!isSeenRelease ? (
          <div style={{ paddingLeft: 10 }}>Click here to see whats new </div>
        ) : (
          ""
        )}
      </button>
      {show ? (
        <Dialog
          titleColor="blue"
          containerStyle={{
            top: "30px",
            transform: "unset",
            left: 0,
          }}
          id="create-change-request"
          open={show}
          title="Whats new?"
          closeIcon
          size="lg"
          closeFn={() => {
            setShow(false);
          }}
        >
          <DialogContent>
            <div>
              <h2>
                Release Announcement – 02/25/2025: New Features & Enhancements
              </h2>
              <p>
                We are excited to introduce a new update featuring significant
                improvements and enhancements to Web Finance Direct. This
                release focuses on optimizing system performance, addressing
                user-reported issues, and introducing new functionality to
                enhance your experience.
              </p>

              <h3>Key Enhancements:</h3>
              <ul>
                <li>
                  <strong>
                    New Documents Section in the Application Screen
                  </strong>
                  <ul>
                    <li>
                      The new Documents section has been introduced and is now
                      divided into four categories:
                    </li>
                    <li>
                      <strong>Stipulations</strong> – This section contains all
                      required stipulations. Users can create new stipulations
                      using the “Create Stipulation” button and generate
                      shareable links for applicants with the “Show Link for
                      Applicants” button. A table displays all created
                      stipulations along with their current statuses.
                    </li>
                    <li>
                      <strong>Funding Documents</strong> – Users can upload all
                      necessary funding documents for an application. Files can
                      be uploaded via drag-and-drop or by selecting the “Upload
                      Documents” button.
                    </li>
                    <li>
                      <strong>Other Documents</strong> – This section stores any
                      additional documents related to the application. Files can
                      be uploaded via drag-and-drop or the “Upload Documents”
                      button.
                    </li>
                    <li>
                      <strong>Title Documents</strong> – This section houses all
                      title-related documents for the application. Files can be
                      uploaded via drag-and-drop or the “Upload Documents”
                      button.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Improved Visibility of Online Applications</strong>
                  <ul>
                    <li>
                      Once an applicant submits an online application, it will
                      now be immediately visible in the Applications screen.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Automated Stipulation Creation</strong>
                  <ul>
                    <li>
                      Stipulations will now be automatically generated upon
                      completion of the application processing. Users will
                      receive a notification when this occurs.
                    </li>
                  </ul>
                </li>
              </ul>

              <h3>Video Tutorial on New Features</h3>

              <div style={{ display: "flex", justifyContent: "center" }}>
                <video
                  width="800px"
                  height="auto"
                  controls
                  style={{ marginBottom: 20 }}
                >
                  <source src="/video_releases/2025-2-21-release.mp4"></source>
                  Your browser does not support the video tag.
                </video>
              </div>

              <p>
                We are committed to continuously improving Web Finance Direct to
                better serve your needs. Stay tuned for more updates!
              </p>

              <p>Best regards,</p>
              <p>The Web Finance Direct Team</p>
            </div>
          </DialogContent>
          <DialogActions>
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <Checkbox
                id={"dont-show-on-startup"}
                placeholder="Don't show on startup until the next release"
                checked={isSeenRelease}
                onChange={() => {
                  setIsSeenRelease(!isSeenRelease);
                }}
              />
              <Button
                variant="blue"
                style={{ maxWidth: "200px" }}
                onClick={() => {
                  setShow(false);
                }}
              >
                Close
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
};
export default WhatsNew;
