import Grid from "@Components/Grid";
import Paper from "@Components/Paper";
import useCurrentUser from "@Hooks/useCurrentUser";
import { renderFieldData } from "@Pages/Applications/CreateApplication/ReviewApplication/ReviewApplication";
import { TradeIn as TradeInType } from "@Types/deal";
import { formatPrice } from "@Utils/functions";
import { hasSectionPermissions } from "@Utils/permissions";

type TradeInProps = {
  tradeIn: TradeInType | undefined;
  payoff: number | undefined;
};

const TradeIn = ({ tradeIn, payoff }: TradeInProps) => {
  const currentUser = useCurrentUser();
  if (!hasSectionPermissions("deal", "trade-in", currentUser)) return null;

  return tradeIn ? (
    <Paper
      title={"Trade In"}
      style={{ height: "100%", boxSizing: "border-box" }}
    >
      <Grid container xs={12}>
        <Grid xs={12}>{renderFieldData("VIN", tradeIn.VIN ?? "N/A")}</Grid>
        <Grid xs={12}>
          {renderFieldData("Year", tradeIn?.year?.toString() ?? "N/A")}
        </Grid>
        <Grid xs={12}>{renderFieldData("Make", tradeIn.make ?? "N/A")}</Grid>
        <Grid xs={12}>{renderFieldData("Model", tradeIn.model ?? "N/A")}</Grid>
        <Grid xs={12}>
          {renderFieldData("Odometer", tradeIn?.odometer?.toString() ?? "N/A")}
        </Grid>
        <Grid xs={12}>
          {renderFieldData("Value", formatPrice(tradeIn?.value) ?? "N/A")}
        </Grid>
        <Grid xs={12}>
          {renderFieldData("Payoff", formatPrice(payoff) ?? "N/A")}
        </Grid>
      </Grid>
    </Paper>
  ) : (
    <Paper
      title={"Trade In"}
      style={{ height: "100%", boxSizing: "border-box" }}
    >
      <p>No trade-in</p>
    </Paper>
  );
};
export default TradeIn;
