import { useRef } from "react";

import Button from "@Components/Button";
import Loading from "@Components/Loading";
import useCurrentUser from "@Hooks/useCurrentUser";
import { useUploadStipulation } from "@Hooks/useStipulations";
import { Stipulation } from "@Types/stipulations";
import {
  hasActionPermissions,
  hasSectionPermissions,
} from "@Utils/permissions";
import mixpanel from "mixpanel-browser";
import styles from "./UploadStipulations.module.scss";

type UploadStipulationsProps = {
  refetch?: () => Promise<void>;
  stipulation: Stipulation;
};
const UploadStipulations = ({
  stipulation,
  refetch,
}: UploadStipulationsProps) => {
  const currentUser = useCurrentUser();
  if (
    !hasSectionPermissions("deal", "stipulations", currentUser) ||
    !hasActionPermissions("stipulation", "update", currentUser)
  )
    return null;
  const { uploadStipulation, loading } = useUploadStipulation(refetch);

  const inputFile = useRef<HTMLInputElement | null>(null);
  const handleFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    stipulation: Stipulation
  ) => {
    if (e?.target?.files && e?.target?.files?.length) {
      handleUploadStipulation(e.target.files[0], stipulation);
    }
  };
  const user = useCurrentUser();
  const handleUploadStipulation = (file: File, stipulation: Stipulation) => {
    uploadStipulation({
      ...stipulation,
      file,
    });
    mixpanel.track("Stipulation upload", {
      email: user?.data?.info?.email,
      dealershipName: user?.data?.dealership?.data?.info?.name,
    });
  };

  return (
    <Button
      variant="blue"
      className={styles.button}
      style={{ paddingLeft: 10, paddingRight: 10, minWidth: "70px" }}
      onClick={(e) => {
        e.stopPropagation();
        document.getElementById(`file-${stipulation?._id}`)?.click();
      }}
    >
      {loading ? (
        <Loading size={15} customLoadingSpinnerStyle={{ top: 0 }} />
      ) : (
        <div style={{ fontSize: "14px" }}>
          {stipulation?.data?.info?.url ? "Re-upload" : "Upload"}
          <input
            ref={inputFile}
            type="file"
            id={`file-${stipulation?._id}`}
            hidden
            onChange={(e) => {
              handleFileChange(e, stipulation);
            }}
          />
        </div>
      )}
    </Button>
  );
};

export default UploadStipulations;
