import Button from "@Components/Button";
import Dialog from "@Components/Dialog";
import { DialogActions } from "@Components/Dialog/DialogActions";
import { DialogContent } from "@Components/Dialog/DialogContent";
import Grid from "@Components/Grid";
import IconButton from "@Components/IconButton";
import TextField from "@Components/Inputs/TextField";
import Loading from "@Components/Loading";
import Radio from "@Components/Radio";
import Select from "@Components/Select";
import useCreateStipulations from "@Hooks/useCreateStipulations";
import useCurrentUser from "@Hooks/useCurrentUser";
import useGetDefaultStipulations from "@Hooks/useDefaultStipulations";

import { Deal } from "@Types/deal";
import { Stipulation } from "@Types/stipulations";
import { hasActionPermissions } from "@Utils/permissions";
import mixpanel from "mixpanel-browser";
import { Dispatch, Fragment, SetStateAction, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BsFillTrashFill } from "react-icons/bs";
import { toast } from "react-toastify";
import styles from "./CreateStipulationDialog.module.scss";

interface Props {
  openCreateDialog: boolean;
  dealStipulations: Stipulation[];
  setOpenCreateDialog: Dispatch<SetStateAction<boolean>>;
  deal: Deal;
  refetch: () => Promise<void>;
}

const CreateStipulationDialog = ({
  openCreateDialog,
  dealStipulations,
  setOpenCreateDialog,
  deal,
  refetch,
}: Props) => {
  const hasCoApplicant = deal?.data?.coApplicant?._id !== undefined;
  const user = useCurrentUser();
  if (!hasActionPermissions("stipulation", "create", user)) {
    return null;
  }
  const defaultState = {
    dealId: deal._id,
    description: "",
    applicant: true,
    owner: undefined as string | undefined,
    defaultDescription: "",
    systemName: undefined as string | undefined,
    defaultStipulationId: undefined as string | undefined,
    coApplicant: false,
  };
  const [stipulations, setStipulations] = useState([defaultState]);
  const { data: defaultStipulations } = useGetDefaultStipulations({
    with_deleted: false,
    options: {
      limit: 100,
      sort: {
        "data.info.description": "asc",
      },
    },
  });
  const { createStipulations, loading, error } = useCreateStipulations();
  const handleSelectDefaultStipulation = (
    e: React.ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    setStipulations((prev) => {
      const choosenDefaultStipulation = defaultStipulations?.find(
        (x) => x._id === e.target.value
      );
      if (!choosenDefaultStipulation) {
        return prev;
      }

      const newState = [...prev];
      newState[index].defaultDescription =
        choosenDefaultStipulation.data.info.description;
      newState[index].systemName =
        choosenDefaultStipulation?.data?.info?.systemName;
      newState[index].owner =
        choosenDefaultStipulation?.data?.info?.owner || "applicant";
      newState[index].defaultStipulationId = choosenDefaultStipulation?._id;

      if (choosenDefaultStipulation.data.info.systemName === "other") {
        newState[index].description = "";
        newState[index]["applicant"] = true;
        newState[index]["coApplicant"] = hasCoApplicant;
      } else {
        newState[index].description =
          choosenDefaultStipulation.data.info.description;
        (
          choosenDefaultStipulation?.data?.info?.canBeUploadedBy?.filter(
            (x) => x !== "dealer"
          ) as ("applicant" | "coApplicant")[]
        )?.forEach((x) => {
          newState[index].applicant = x === "applicant";
          newState[index].coApplicant = x === "coApplicant" && hasCoApplicant;
        });
      }
      return newState;
    });
  };
  const handleSendCreateRequest = async () => {
    if (
      stipulations.some(
        (stipulation) => !stipulation.applicant && !stipulation.coApplicant
      )
    ) {
      toast.error("Applicant or co-applicant must be selected", {
        position: "top-center",
      });
      return;
    }
    if (stipulations.some((stipulation) => !stipulation.description)) {
      toast.error("Please fill description", {
        position: "top-center",
      });
      return;
    }
    if (
      stipulations.some((stipulation) => stipulation.applicant) &&
      !deal?.data?.applicant?.data?.info?.email
    ) {
      toast.error(
        "Applicant email is missing. Please contact a F&I Manager to add the email address.",
        {
          position: "top-center",
        }
      );
      return;
    }
    if (stipulations.some((stipulation) => !stipulation.owner)) {
      toast.error("Owner should be selected", {
        position: "top-center",
      });
      return;
    }
    if (
      stipulations.some((stipulation) => stipulation.coApplicant) &&
      !deal?.data?.coApplicant?.data?.info?.email
    ) {
      toast.error(
        "Co-applicant email is missing. Please contact a F&I Manager to add the email address.",
        {
          position: "top-center",
        }
      );
      return;
    }

    mixpanel.track("Stipulation request", {
      email: user?.data?.info?.email,
      dealershipName: user?.data?.dealership?.data?.info?.name,
    });
    createStipulations(
      stipulations.map((stipulation) => {
        return {
          description: stipulation.description,
          defaultStipulationId: stipulation.defaultStipulationId,
          applicant: true,
          owner: stipulation.owner as "applicant" | "coApplicant",
          coApplicant: hasCoApplicant,
          dealId: deal._id,
        };
      })
    )
      .then(async () => {
        await refetch?.();
        setOpenCreateDialog(false);
        setStipulations([defaultState]);
      })
      .catch(() => {
        toast.error("Error sending stipulation request", {
          position: "top-center",
        });
      });
  };
  return (
    <Dialog
      titleColor="blue"
      id="create-change-request"
      open={openCreateDialog}
      title="Request stipulation"
      closeIcon
      size="xl"
      closeFn={() => {
        let confirmed = false;
        const allStipulationsAreEmpty = stipulations.every(
          (x) => x.defaultDescription === "" && x.description === ""
        );
        if (!allStipulationsAreEmpty) {
          confirmed = confirm(
            "Closing the dialog will discard all changes. Are you sure you want to close?"
          );
        } else {
          confirmed = true;
        }
        if (confirmed) {
          setOpenCreateDialog(false);
          setStipulations([defaultState]);
        }
      }}
      containerStyle={{ width: "80%", margin: "0 10%" }}
    >
      <DialogContent>
        <div>
          <p>
            In order to request a stipulation, please fill out the form below
            using the instructions provided:
          </p>
          <p>
            <ol>
              <li style={{ fontSize: "14px", fontWeight: 500 }}>
                Select a stipulation from the list or create a new one using the
                &ldquo;<strong>Other</strong>&ldquo; option.
              </li>
              <li style={{ fontSize: "14px", fontWeight: 500 }}>
                If you select the &ldquo;<strong>Other</strong>&ldquo; option,
                you must manually fill in the description.
              </li>

              <li style={{ fontSize: "14px", fontWeight: 500 }}>
                Select the stipulation owner (the person required to upload the
                document) - either the applicant, or the co-applicant, using the
                radio buttons.
              </li>
              <li style={{ fontSize: "14px", fontWeight: 500 }}>
                If you want to create multiple stipulations, please add a
                separate row for each document. To do this, use the &ldquo;
                <strong>Add another stipulation</strong>&ldquo; button.
              </li>
              <li style={{ fontSize: "14px", fontWeight: 500 }}>
                Click the &ldquo;<strong>Save & Send email</strong>&rdquo;
                button to create the stipulation and send an email to the
                selected parties.
              </li>
            </ol>
          </p>
        </div>
        {error && (
          <div
            style={{
              color: "red",
              padding: "10px",
              border: "1px solid red",
              borderRadius: "5px",
              marginBottom: "10px",
            }}
          >
            {error.message}
          </div>
        )}
        {loading && <Loading size={30} text={"Loading"} />}
        {!loading &&
          stipulations.map((stipulation, index) => (
            <Fragment key={index}>
              <div className={styles.form} style={{ marginBottom: 20 }}>
                <IconButton
                  onClick={() => {
                    setStipulations((prev) => {
                      const newState = [...prev];
                      newState.splice(index, 1);
                      return newState;
                    });
                  }}
                  style={{
                    position: "absolute",
                    right: 20,
                    top: 5,
                    fontSize: 25,
                    height: 30,
                    width: 30,
                    color: "red",
                    zIndex: 1,
                  }}
                >
                  <BsFillTrashFill />
                </IconButton>
                <Grid container spacing={2}>
                  <Grid xs={12} md={8}>
                    <Grid xs={12}>
                      Select stipulation or create one using the &ldquo;
                      <strong>Other</strong>&ldquo; option
                    </Grid>
                    <Grid container>
                      <Grid xs={6}>
                        <Select
                          name="Default stipulations"
                          label="Choose stipulation type"
                          value={stipulation.defaultStipulationId}
                          onChange={(e) =>
                            handleSelectDefaultStipulation(e, index)
                          }
                          options={[...(defaultStipulations || [])]
                            .filter((s) =>
                              s.data.info.owner === "coApplicant" &&
                              !deal?.data?.coApplicant?._id
                                ? false
                                : true
                            )
                            .filter(
                              (defaultStipulation) =>
                                !dealStipulations
                                  .map((x) => x.data.defaultStipulationId)
                                  .includes(defaultStipulation._id) ||
                                defaultStipulation.data.info.systemName ===
                                  "other"
                            )
                            .map((defaultStipulation) => ({
                              value: defaultStipulation?._id,
                              label:
                                defaultStipulation?.data?.info?.description,
                            }))}
                        />
                      </Grid>
                      <Grid xs={6}>
                        {stipulation.systemName === "other" ? (
                          <TextField
                            onChange={(e) => {
                              setStipulations((prev) => {
                                const newState = [...prev];
                                newState[index].description = e.target.value;
                                return newState;
                              });
                              // setCurrentStipulationDescription(e.target.value);
                            }}
                            placeholder="Name"
                            value={stipulation.description ?? ""}
                            name="Description"
                            type="text"
                            required
                            id="Description"
                          />
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={12} md={4}>
                    {/* <Grid xs={12}>Can be uploaded by?</Grid>
                    <Grid
                      xs={12}
                      style={{
                        paddingTop: 25,
                        paddingLeft: 20,
                        marginBottom: 10,
                      }}
                      container
                    >
                      <Checkbox
                        containerStyle={{
                          display: "inline-block",
                          width: "auto",
                          marginRight: 20,
                        }}
                        onChange={(e) => {
                          setStipulations((prev) => {
                            const newState = [...prev];
                            newState[index].applicant = hasCoApplicant
                              ? e.target.checked
                              : true;
                            return newState;
                          });
                        }}
                        placeholder="applicant"
                        checked={stipulation?.applicant}
                        name={"applicant" + index}
                        id={"applicant" + index}
                      />

                      {hasCoApplicant && (
                        <Checkbox
                          containerStyle={{
                            display: "inline-block",
                            width: "auto",
                          }}
                          onChange={(e) =>
                            setStipulations((prev) => {
                              const newState = [...prev];
                              newState[index].coApplicant = e.target.checked;
                              return newState;
                            })
                          }
                          placeholder="Co-Applicant"
                          checked={stipulation?.coApplicant}
                          name={"coApplicant" + index}
                          id={"coApplicant" + index}
                        />
                      )}
                    </Grid> */}
                    {stipulation.systemName === "other" && (
                      <>
                        <Grid xs={12}>Document owner</Grid>
                        <Grid
                          xs={12}
                          style={{
                            display: "inline-block",
                            width: "auto",
                          }}
                          container
                        >
                          <Radio
                            id={"applicantOwner" + index}
                            group="ownerOfStipulation"
                            containerStyle={{
                              display: "inline-block",
                              width: "auto",
                              background: "none",
                              fontSize: "14px",
                              paddingLeft: "10px",
                            }}
                            outerLabelStyle=""
                            possibleValues={[
                              {
                                value: "applicant",
                                label: "Applicant",
                              },
                            ]}
                            required
                            onChange={(e) => {
                              switch (e.target.value) {
                                case "applicant":
                                  setStipulations((prev) => {
                                    const newState = [...prev];
                                    newState[index].owner = e.target.value;
                                    return newState;
                                  });
                                  break;
                                default:
                                  setStipulations((prev) => {
                                    const newState = [...prev];
                                    newState[index].owner = undefined;
                                    return newState;
                                  });
                                  break;
                              }
                            }}
                            selectedValue={stipulation.owner}
                          />
                        </Grid>
                        {hasCoApplicant && (
                          <Radio
                            id={"coApplicantOwner" + index}
                            group="ownerOfStipulation"
                            containerStyle={{
                              display: "inline-block",
                              width: "auto",
                              height: "auto",
                              background: "none",
                              fontSize: "14px",
                              padding: "10px 4px",
                            }}
                            outerLabelStyle=""
                            possibleValues={[
                              {
                                value: "coApplicant",
                                label: "Co-Applicant",
                              },
                            ]}
                            required
                            onChange={(e) => {
                              switch (e.target.value) {
                                case "coApplicant":
                                  setStipulations((prev) => {
                                    const newState = [...prev];
                                    newState[index].owner = e.target.value;
                                    return newState;
                                  });
                                  break;
                                default:
                                  setStipulations((prev) => {
                                    const newState = [...prev];
                                    newState[index].owner = undefined;
                                    return newState;
                                  });
                                  break;
                              }
                            }}
                            selectedValue={stipulation.owner}
                          />
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </div>
            </Fragment>
          ))}
        {!loading && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <IconButton
              onClick={() => {
                setStipulations((prev) => [
                  ...prev,
                  {
                    dealId: deal._id,
                    defaultDescription: "",
                    description: "",
                    owner: undefined,
                    defaultStipulationId: undefined,
                    applicant: true,
                    coApplicant: false,
                    systemName: undefined,
                  },
                ]);
              }}
              style={{
                float: "right",
                width: "40%",
                margin: "20px 0",
                display: "flex",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <AiOutlinePlusCircle
                size={30}
                style={{ paddingRight: "10px", color: "green" }}
              />
              <span
                style={{
                  color: "green",
                  fontSize: 20,
                  fontWeight: 600,
                }}
              >
                Add another stipulation
              </span>
            </IconButton>
          </div>
        )}
      </DialogContent>
      {!loading && (
        <DialogActions align="end">
          <Button
            style={{
              width: "auto",
              marginLeft: "auto",
              padding: "0px 20px",
              whiteSpace: "nowrap",
            }}
            variant={
              stipulations.some(
                (stipulation) =>
                  (!stipulation.applicant && !stipulation.coApplicant) ||
                  !stipulation.description ||
                  stipulation?.owner === undefined
              )
                ? "pink"
                : "blue"
            }
            type="button"
            onClick={handleSendCreateRequest}
          >
            {stipulations.some(
              (stipulation) =>
                !stipulation.applicant && !stipulation.coApplicant
            )
              ? "Applicant or co-applicant must be selected"
              : stipulations.some((stipulation) => !stipulation.description)
              ? "Description must be filled"
              : stipulations?.some(
                  (stipulation) => stipulation?.owner === undefined
                )
              ? "Owner should be selected"
              : "Save & Send email"}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
export default CreateStipulationDialog;
