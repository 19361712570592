import { useState } from "react";

import Filter from "@Components/Filters/Filter";
import { transformFiltersToQuery } from "@Components/Filters/filterUtills";
import { Filters } from "@Components/Filters/types";

import styles from "./DocumentsTable.module.scss";

import Grid from "@Components/Grid";
import Loading from "@Components/Loading";
import Sort, { transformSort } from "@Components/Table/Sort/Sort";
import SortBy from "@Components/Table/Sort/types";
import Tooltip from "@Components/Tooltip";

import Dropzone from "@Components/Dropzone";
import useCurrentUser from "@Hooks/useCurrentUser";
import {
  useCreateDocument,
  useCreateFundedDocument,
} from "@Hooks/useDocuments";
import { Document } from "@Types/documents";
import mixpanel from "mixpanel-browser";
import { IoHelpCircleOutline } from "react-icons/io5";
import DocumentsUploadButton from "../../DocumentsUploadButton";
import InactiveSection from "../InactiveSection";
import { TableProps } from "./types";

export function truncate(
  input: string | undefined,
  length: number
): string | undefined {
  if (input && input?.length > length + 3) {
    return input.substring(0, length) + "...";
  }
  return input;
}

const DocumentsTable = <T extends Document>({
  columns,
  useGetFunction,
  dropzoneRef,
  useDocumentType,
  deal,
  query,
  type,
  width,
  tableTitle,
  queryKey,
  projection,
}: TableProps<T>) => {
  const [filters, setFilters] = useState<Filters | undefined>(undefined);
  const [sort, setSort] = useState<SortBy | undefined>();
  const [hoverRow, setHoverRow] = useState<number | undefined>();
  const [file, setFile] = useState<File | undefined>();
  const user = useCurrentUser();

  const {
    data: entries,
    isLoading,
    refetch,
  } = useGetFunction(queryKey, {
    query: {
      ...(filters ? transformFiltersToQuery(filters) : {}),
      ...(query ?? {}),
    },
    options: {
      limit: 100,
      ...(sort ? transformSort(sort) : { sort: { createdAt: "desc" } }),
      ...(projection ? { projection } : {}),
    },
  });
  const { createDocument } =
    type === "other_documents"
      ? useCreateDocument(() => {
          setFile(undefined);
          refetch?.();
        })
      : useCreateFundedDocument(() => {
          setFile(undefined);
          refetch?.();
        });

  const handleCreateDocument = (file: File) => {
    setFile(file);
    createDocument({
      data: {
        incompleteDealId: null,
        dealId: deal._id,
        info: {
          name: file.name,
        },
      },
      file: file,
    });
    mixpanel.track(
      type === "other_documents"
        ? "Document uploaded before signing"
        : "Signed document uploaded",
      {
        email: user?.data?.info?.email,
        dealershipName: user?.data?.dealership?.data?.info?.name,
      }
    );
  };
  return (
    <div style={{ position: "relative", height: "100%" }} ref={dropzoneRef}>
      <Dropzone
        createDocument={handleCreateDocument}
        content={
          <div
            style={{
              fontWeight: 600,
              color: "#5e5d65",
              fontSize: "20px",
            }}
          >
            Drop files here to upload
          </div>
        }
        customZoneStyle={{ border: "3px dashed #6b97f6", borderRadius: "5px" }}
        containerRef={dropzoneRef}
      />
      {file && (
        <div
          style={{
            display: "flex",
            position: "absolute",
            right: "0px",
            bottom: "0px",
            left: "0px",
            top: "0px",
            zIndex: 1200,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(240,248,255,0.5)",
            border: "3px dashed #6b97f6",
            borderRadius: "5px",
          }}
        >
          <Loading
            size={25}
            customTextNode={
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: 600,
                  color: "#5e5d65",
                  paddingLeft: "5px",
                  display: "inline-block",
                  paddingTop: "4px",
                }}
              >
                Uploading...
              </div>
            }
          />
        </div>
      )}
      <Grid
        container
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Grid xs={width === "full" ? 9 : 6}>
          <div
            style={{
              display: "flex",
              gap: "5px",
              alignItems: "center",
              whiteSpace: "nowrap",
            }}
          >
            <div className={styles.tableTitle}>{tableTitle}</div>

            {type === "other_documents" && (
              <Tooltip
                content={
                  "Upload any additional documents that are not stipulations in this section."
                }
                tooltipStyle={{
                  backgroundColor: "#b2cbff",
                  padding: 10,
                  fontWeight: 500,
                  fontSize: 14,
                  width: "200px",
                  whiteSpace: "normal",
                }}
              >
                <IoHelpCircleOutline
                  cursor={"pointer"}
                  color="#6b97f6"
                  size={25}
                />
              </Tooltip>
            )}
            {type === "funded_documents" && (
              <Tooltip
                content={
                  "Upload any documents related to the funding of the deal in this section."
                }
                tooltipStyle={{
                  backgroundColor: "#b2cbff",
                  padding: 10,
                  fontWeight: 500,
                  fontSize: 14,
                  width: "200px",
                  whiteSpace: "normal",
                }}
              >
                <IoHelpCircleOutline
                  cursor={"pointer"}
                  color="#6b97f6"
                  size={25}
                />
              </Tooltip>
            )}
          </div>
        </Grid>

        <Grid xs={width === "full" ? 3 : 6}>
          <div
            style={{
              display: "flex",
              gap: "5px",
              justifyContent: "flex-end",
            }}
          >
            <DocumentsUploadButton
              fullWidth
              refetch={refetch}
              section={"required-documents"}
              dealId={deal._id}
              dashed
              useDocument={useDocumentType}
            />
          </div>
        </Grid>
        <Grid xs={12} style={{ marginTop: -10 }}>
          <div className={styles.tableTitleSecondary}>
            (You can drag files in this section to upload)
          </div>
        </Grid>
        <Grid xs={12} style={{ maxHeight: "300px", overflowY: "auto" }}>
          {isLoading ? (
            <InactiveSection
              containerStyle={{ minHeight: "40px" }}
              showInfo={false}
              node={
                <Loading
                  size={25}
                  customTextNode={
                    <div
                      style={{
                        fontSize: "18px",
                        paddingLeft: "5px",
                        display: "inline-block",
                        paddingTop: "4px",
                      }}
                    >
                      Loading...
                    </div>
                  }
                />
              }
            />
          ) : entries?.length || Object.keys(filters ?? {})?.length ? (
            <table
              cellSpacing={0}
              cellPadding={0}
              border={0}
              className={styles.table}
            >
              <thead className={styles.tableHead}>
                <tr>
                  {columns.map((column, index) => (
                    <th key={index} className={styles.columnHeader}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                          gap: "5px",
                        }}
                      >
                        {column.label}
                        {column.sortPath && (
                          <Sort
                            sort={sort}
                            path={column.sortPath}
                            setSort={setSort}
                          />
                        )}
                        {column?.filters && (
                          <Filter
                            id={column.id}
                            columnFilters={column.filters}
                            filterState={filters?.[column.id]}
                            setFilterState={(newFilterState) => {
                              setFilters((prevFilters) => ({
                                ...prevFilters,
                                [column.id]: newFilterState,
                              }));
                            }}
                          />
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {entries?.length ? (
                  entries.map((entry, index) => {
                    return (
                      <tr
                        key={index}
                        className={styles.row}
                        onMouseEnter={() => {
                          setHoverRow(index);
                        }}
                        onMouseLeave={() => {
                          setHoverRow(undefined);
                        }}
                      >
                        {columns.map((column, columnIndex) => {
                          const style = column?.style
                            ? column?.style(entry)
                            : {};
                          const value = column.value(
                            entry,
                            column?.valueOptionsEnabled
                              ? { refetch }
                              : undefined
                          );
                          return (
                            <td key={columnIndex} className={styles.column}>
                              <div
                                className={styles.columnContainer}
                                style={
                                  column?.id === "actions"
                                    ? {
                                        ...style,
                                        paddingLeft: "10px",
                                      }
                                    : column?.id === "delete"
                                    ? {
                                        ...style,
                                        paddingLeft: "10px",
                                      }
                                    : style
                                }
                              >
                                {column.hoverIcon && index === hoverRow
                                  ? typeof column.hoverIcon === "function"
                                    ? column.hoverIcon(entry)
                                    : column.hoverIcon
                                  : typeof column.icon === "function"
                                  ? column.icon(entry)
                                  : column.icon}

                                <span>
                                  {" "}
                                  {column.truncate && typeof value === "string"
                                    ? truncate(value, column.truncate)
                                    : column.value(
                                        entry,
                                        column?.valueOptionsEnabled
                                          ? { refetch }
                                          : undefined
                                      )}
                                </span>
                              </div>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <tr className={styles.notFound}>
                    <td
                      colSpan={columns.length ?? 1}
                      style={{
                        fontSize: "18px",
                        minHeight: "40px",
                        padding: "15px",
                      }}
                    >
                      No entries
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          ) : (
            <InactiveSection
              containerStyle={{ minHeight: "40px" }}
              showInfo={false}
              node={<>No entries</>}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};
export default DocumentsTable;
