import {
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useMemo,
  useState,
} from "react";

const useMultiSelect = <T extends { _id: string }>(
  options: T[],
  selectedItems: T[],
  getOptionLabel: (item: T) => string,
  getOptionSelected: (x: T, y: T) => boolean,
  onChange: (selectedItems: T[]) => void
) => {
  const [searchValue, setSearchValue] = useState("");
  const [filteredItemsList, setFilteredItemList] = useState<T[]>([]);

  const [showList, setShowList] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [lastHightlight, setLasHightlight] = useState(false);

  const upHandler = () => {
    setSelectedIndex((i) =>
      i - 1 >= 0 ? i - 1 : filteredItemsList.length - 1
    );
  };

  const downHandler = () => {
    setSelectedIndex((i) => (i < filteredItemsList.length - 1 ? i + 1 : 0));
  };

  const enterHandler = (ctrlKey: boolean) => {
    if (filteredItemsList.length <= 0) return;
    if (searchValue.length === 0 && !showList) {
      setShowList(true);
      return;
    }
    onChange([...selectedItems, filteredItemsList[selectedIndex]]);
    setSearchValue("");
    setShowList(ctrlKey);
  };

  const backspaceHandler = () => {
    if (searchValue.length === 0 && selectedItems.length > 0) {
      if (!lastHightlight) setLasHightlight(true);
      else {
        onChange(selectedItems.slice(0, -1));
        setLasHightlight(false);
      }
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case "ArrowUp":
        e.preventDefault();
        upHandler();
        return true;

      case "ArrowDown":
        e.preventDefault();
        downHandler();
        return true;

      case "Enter":
        console.log(e);
        e.preventDefault();
        enterHandler(e.ctrlKey);
        return true;

      case "Backspace":
        backspaceHandler();
        return true;

      default:
        return true;
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    setSelectedIndex(0);
    setShowList(true);
  };

  const remove = (e: MouseEvent<HTMLButtonElement>, item: T) => {
    e.stopPropagation();
    e.preventDefault();
    onChange(
      selectedItems.filter(
        (selectedItem) => !getOptionSelected(selectedItem, item)
      )
    );
  };

  const add = (item: T) => {
    onChange([...selectedItems, item]);
    setSearchValue("");
  };

  const validfilteredList = useMemo(
    () =>
      options.filter(
        (item1) =>
          !selectedItems.some((item2) => getOptionSelected(item1, item2))
      ),
    [selectedItems, options]
  );

  useEffect(() => {
    if (searchValue.length == 0) {
      setFilteredItemList(validfilteredList);
      return;
    }
    const filteredList = validfilteredList.filter((option) =>
      getOptionLabel(option)
        .toLocaleLowerCase()
        .includes(searchValue.toLowerCase())
    );
    setFilteredItemList(filteredList);
  }, [searchValue, validfilteredList]);

  return {
    showList,
    setShowList,
    selectedItems,
    filteredItemsList,
    remove,
    add,
    lastHightlight,
    searchValue,
    handleKeyDown,
    handleOnChange,
    setFilteredItemList,
    selectedIndex,
  };
};

export default useMultiSelect;
