import Dropzone from "@Components/Dropzone";
import Grid from "@Components/Grid";
import Loading from "@Components/Loading";
import Tooltip from "@Components/Tooltip";
import TitleIssueDocumentsDialog from "@Pages/TitleIssues/TitleIssueDocumentsDialog";
import UploadTitleIssueDocument from "@Pages/TitleIssues/UploadTitleIssueDocument";
import { Deal, DealStatus } from "@Types/deal";
import { TitleIssue } from "@Types/title_issues";
import { useRef, useState } from "react";
import { IoHelpCircleOutline } from "react-icons/io5";
import DocumentsUploadButton from "../../DocumentsUploadButton";
import InactiveSection from "../InactiveSection";
import classes from "./TitleDocuments.module.scss";
type TitleDocumentsProps = {
  titleIssue: TitleIssue | undefined;
  deal: Deal;
};
const sectionEnabled = (
  status: DealStatus,
  titleIssue: TitleIssue | undefined
) =>
  (status === DealStatus.FundingHeld || status === DealStatus.Funded) &&
  titleIssue !== undefined;
const TitleDocuments = ({ deal, titleIssue }: TitleDocumentsProps) => {
  const [open, setOpen] = useState(false);
  const [uploadWithDropzone, setUploadWithDropzone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | undefined>();

  const dropzoneRef = useRef(null);

  const handleOpenDialog = (newFile: File) => {
    setOpen(true);
    setFile(newFile);
  };
  return (
    <div className={classes.container} ref={dropzoneRef}>
      <Dropzone
        createDocument={(file) => {
          handleOpenDialog(file);
          setUploadWithDropzone(true);
        }}
        content={
          <div
            style={{
              fontWeight: 600,
              color: "#5e5d65",
              fontSize: "20px",
            }}
          >
            Drop files here to upload
          </div>
        }
        customZoneStyle={{ border: "3px dashed #6b97f6", borderRadius: "5px" }}
        containerRef={dropzoneRef}
      />
      {file && loading && uploadWithDropzone && (
        <div
          style={{
            display: "flex",
            position: "absolute",
            right: "0px",
            bottom: "0px",
            left: "0px",
            top: "0px",
            zIndex: 1200,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(240,248,255,0.5)",
            border: "3px dashed #6b97f6",
            borderRadius: "5px",
          }}
        >
          <Loading
            size={25}
            customTextNode={
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: 600,
                  color: "#5e5d65",
                  paddingLeft: "5px",
                  display: "inline-block",
                  paddingTop: "4px",
                }}
              >
                Uploading...
              </div>
            }
          />
        </div>
      )}
      <div style={{ height: "100%", padding: 9 }}>
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className={classes.title}>
              <span style={{ marginRight: 5 }}>Title documents</span>
              <Tooltip
                content={"Upload documents related to the title of the loan."}
                tooltipStyle={{
                  backgroundColor: "#b2cbff",
                  padding: 10,
                  fontWeight: 500,
                  fontSize: 14,
                  width: "200px",
                  whiteSpace: "normal",
                }}
              >
                <IoHelpCircleOutline
                  cursor={"pointer"}
                  color="#6b97f6"
                  size={25}
                />
              </Tooltip>
            </div>

            {loading ? (
              <Loading
                size={25}
                customTextNode={
                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: 600,
                      color: "#5e5d65",
                      paddingLeft: "5px",
                      display: "inline-block",
                      paddingTop: "4px",
                    }}
                  >
                    Uploading...
                  </div>
                }
              />
            ) : (
              sectionEnabled(deal.data.info.status, titleIssue) && (
                <DocumentsUploadButton
                  dealId=""
                  section={"title-documents"}
                  dashed
                  handleCreate={handleOpenDialog}
                />
              )
            )}
          </div>
        </div>
        {sectionEnabled(deal.data.info.status, titleIssue) && (
          <div
            style={{ marginTop: -10 }}
            className={classes.tableTitleSecondary}
          >
            (You can drag files in this section to upload)
          </div>
        )}
        {sectionEnabled(deal.data.info.status, titleIssue) ? (
          <UploadTitleIssueDocument titleIssue={titleIssue as TitleIssue} />
        ) : (
          <Grid xs={12}>
            <InactiveSection
              node={
                <>
                  This section is currently inactive. It will become available
                  once the deal reaches the <strong>&quot;Funded&quot;</strong>{" "}
                  status.
                </>
              }
            />
          </Grid>
        )}
      </div>
      {titleIssue && (
        <TitleIssueDocumentsDialog
          file={file}
          openDialog={open}
          setOpenDialog={setOpen}
          setLoading={setLoading}
          setUploadWithDropzone={setUploadWithDropzone}
          titleIssue={titleIssue}
        />
      )}
    </div>
  );
};
export default TitleDocuments;
