import Grid from "@Components/Grid";
import { TitleIssue } from "@Types/title_issues";

import PreviewDocument from "./PreviewDocument";

import documentsTableClasses from "../Applications/Application/Documents/DocumentsSection/DocumentsTable/DocumentsTable.module.scss";

export default function UploadTitleIssueDocument({
  titleIssue,
}: {
  titleIssue: TitleIssue;
}) {
  const openUrl = (url: string) => window.open(url, "_blank");

  return (
    <Grid xs={12} container>
      <Grid container xs={12}>
        <table
          border={0}
          style={{ width: "100%" }}
          className={documentsTableClasses.table}
        >
          <thead>
            <tr style={{ textAlign: "left" }}>
              <th>Info</th>
              <th>Document name</th>
              <th>Document status</th>
              <th>Documents</th>
            </tr>
          </thead>
          <tbody>
            <PreviewDocument
              title="Copy of the original title"
              tooltip="Signed by dealer and buyer if done in person"
              data={titleIssue?.data?.info?.oldTitleRegistrationInfo}
              setPreviewUrl={(url: string) => openUrl(url)}
            />
            <PreviewDocument
              title="Copy of the completed title application"
              tooltip="Including lien-holder for the appropriate state"
              data={titleIssue?.data?.info?.titleRegistrationInfo}
              setPreviewUrl={(url: string) => openUrl(url)}
            />
            <PreviewDocument
              title="Paid receipt"
              tooltip="Paid receipt from appropriate state DMV"
              data={titleIssue?.data?.info?.receiptInfo}
              setPreviewUrl={(url: string) => openUrl(url)}
            />
          </tbody>
        </table>
      </Grid>
    </Grid>
  );
}
