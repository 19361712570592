import Grid from "@Components/Grid";
import BackButton from "@Components/BackButton";
import Paper from "@Components/Paper";
import { UserForm } from "./UserForm";
import { CreateUserRequest } from "@Types/users";
import { useCreateMember } from "@Hooks/useMembers";

import useCurrentUser from "@Hooks/useCurrentUser";
import mixpanel from "mixpanel-browser";

const User = () => {
  const user = useCurrentUser();
  const { createUser } = useCreateMember();

  const handleSaveChanges = (
    e: React.FormEvent<HTMLFormElement>,
    state: CreateUserRequest | undefined
  ) => {
    e.preventDefault();
    if (state) {
      createUser(state);
      mixpanel.track("New member created", {
        email: user?.data?.info?.email,
        dealershipName: user?.data?.dealership?.data?.info?.name,
        newMemberEmail: state?.data?.info?.email,
        newMemberNames: [
          state?.data?.info?.firstName,
          state?.data?.info?.lastName,
        ]
          ?.filter((x) => x)
          .join(" "),
      });
    }
  };
  return (
    <Grid container xs={12}>
      <Grid xs={1}>
        <BackButton />
      </Grid>
      <Grid container xs={12}>
        <Paper title="New User" style={{ position: "relative", width: "100%" }}>
          <UserForm<CreateUserRequest>
            id="create-user"
            type="create"
            initialState={{
              data: {
                info: {
                  firstName: "",
                  lastName: "",
                  email: "",
                },
                rolesIds: [],
                teamsIds: [],
              },
            }}
            handleSubmit={handleSaveChanges}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default User;
