import useCurrentUser from "@Hooks/useCurrentUser";
import { useDeleteStipulation } from "@Hooks/useStipulations";
import { Stipulation, StipulationStatus } from "@Types/stipulations";
import {
  hasActionPermissions,
  hasSectionPermissions,
} from "@Utils/permissions";
import mixpanel from "mixpanel-browser";
import IconButton from "@Components/IconButton";
import { MdDeleteForever } from "react-icons/md";

type DeleteStipulationProps = {
  refetch?: () => void;
  stipulation: Stipulation;
};
const DeleteStipulation = ({
  stipulation,
  refetch,
}: DeleteStipulationProps) => {
  const currentUser = useCurrentUser();
  if (
    !hasSectionPermissions("deal", "stipulations", currentUser) ||
    !hasActionPermissions("stipulation", "delete", currentUser) ||
    stipulation?.data?.info?.createdBy !== "dealer" ||
    stipulation?.data?.info?.status === StipulationStatus.APPROVED
  ) {
    return null;
  }
  const { deleteStipulation } = useDeleteStipulation(refetch);

  const user = useCurrentUser();
  const handleDeleteStipulation = (stipulation: Stipulation) => {
    let confirmed = false;
    if (stipulation?.data?.info?.url) {
      confirmed = confirm(
        "Deleting this stipulation will also delete the file associated with it, are you sure you want to proceed?"
      );
    } else {
      confirmed = true;
    }
    if (!confirmed) return;
    deleteStipulation(stipulation);
    mixpanel.track("Stipulation delete", {
      email: user?.data?.info?.email,
      dealershipName: user?.data?.dealership?.data?.info?.name,
    });
  };
  return (
    <IconButton
      id={`viewDocument_${stipulation._id}`}
      className={"redCircle"}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleDeleteStipulation(stipulation);
      }}
    >
      <MdDeleteForever size={18} />
    </IconButton>
  );
};

export default DeleteStipulation;
