import {
  deleteStipulation,
  getStipulations,
  uploadStipulation,
} from "@Http/stipulations";
import { HttpQuery } from "@Types/http";
import { MutationError } from "@Types/index";
import { Stipulation } from "@Types/stipulations";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

export const useUploadStipulation = (callback?: () => void) => {
  const mutation = useMutation<
    Stipulation,
    MutationError,
    Stipulation & { file: File }
  >(uploadStipulation(), {
    onSuccess: (updatedStipulation) => {
      console.log(callback);
      callback?.();
      console.log(updatedStipulation);

      toast.success("Successfully uploaded a stipulation and send email.", {
        position: "top-center",
      });
    },
  });
  return {
    uploadStipulation: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};

export const useGetStipulations = (queryKeyProp: string, query?: HttpQuery) => {
  const queryKey = ["stipulations", queryKeyProp];

  const { data, isLoading, error, refetch } = useQuery<
    Stipulation[],
    MutationError,
    Stipulation[]
  >({
    queryKey: queryKey,
    queryFn: getStipulations(query),
  });

  return {
    data,
    isLoading,
    error,
    refetch,
  };
};

export const useDeleteStipulation = (callback?: () => void) => {
  const mutation = useMutation<Stipulation, MutationError, Stipulation>(
    deleteStipulation(),
    {
      onSuccess: () => {
        toast.success("Successfully deleted a stipulation.", {
          position: "top-center",
        });
        callback?.();
      },
    }
  );
  return {
    deleteStipulation: mutation.mutate,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
  };
};
