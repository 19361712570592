import { useState } from "react";
import { IoHelpCircleSharp } from "react-icons/io5";

import Grid from "@Components/Grid";
import Button from "@Components/Button";
import Select from "@Components/Select";
import useGetRoles from "@Hooks/useRoles";
import useGetTeams from "@Hooks/useTeams";
import useCurrentUser from "@Hooks/useCurrentUser";
import TextField from "@Components/Inputs/TextField";
import { User, CreateUserRequest } from "@Types/users";
import { useResendMemberLink } from "@Hooks/useMembers";

import styles from "./User.module.scss";
import Tooltip from "@Components/Tooltip";
import MultiSelect from "@Components/MultiSelect";
import { Role } from "@Types/roles";
import { Team } from "@Types/teams";

type Props<T extends User | CreateUserRequest> = {
  id: string;
  type: "update" | "create";
  emailDisabled?: boolean;
  initialState: T;
  handleSubmit: (
    e: React.FormEvent<HTMLFormElement>,
    user: T | undefined
  ) => void;
};
export function UserForm<T extends User | CreateUserRequest>({
  id,
  type,
  initialState,
  handleSubmit,
  emailDisabled = false,
}: Props<T>) {
  const [state, setState] = useState(initialState);
  const { sendLink } = useResendMemberLink();
  const handleResendLink = () => sendLink(id);
  const user = useCurrentUser();
  const { data: roles, isFetching: isFetchingRoles } = useGetRoles();
  const { data: teams, isFetching: isFetchingTeams } = useGetTeams();
  const publicRoles = roles?.filter((role) => role?.data?.isPublic);
  return (
    <div className={styles.formContainer}>
      <form data-testid={id} id={id} onSubmit={(e) => handleSubmit(e, state)}>
        <Grid container xs={12} spacing={2}>
          <Grid xs={4}>
            <TextField
              onChange={(e) =>
                setState((prev) => ({
                  ...(prev ?? {}),
                  data: {
                    ...(prev?.data ?? {}),
                    info: {
                      ...(prev?.data?.info ?? {}),
                      firstName: e.target.value,
                    },
                  },
                }))
              }
              id="firstName"
              placeholder="First Name"
              value={state?.data.info.firstName ?? ""}
              name="First Name"
              type="text"
              required
            />
          </Grid>
          <Grid xs={4}>
            <TextField
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  data: {
                    ...prev?.data,
                    info: {
                      ...prev?.data.info,
                      lastName: e.target.value,
                    },
                  },
                }))
              }
              id="lastName"
              placeholder="Last Name"
              value={state?.data.info.lastName ?? ""}
              name="Last Name"
              type="text"
              required
            />
          </Grid>
          <Grid xs={4}>
            <TextField
              onChange={
                !emailDisabled
                  ? (e) =>
                      setState((prev) => ({
                        ...prev,
                        data: {
                          ...prev?.data,
                          info: {
                            ...prev?.data.info,
                            email: e.target.value,
                          },
                        },
                      }))
                  : undefined
              }
              id="email"
              disabled={emailDisabled}
              placeholder="Email"
              required
              value={state?.data.info.email ?? ""}
              name="Email"
              type="text"
            />
          </Grid>

          <Grid xs={4}>
            <Select
              placeholder="User type"
              name="userType"
              id="userType"
              required
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  data: {
                    ...prev?.data,
                    info: {
                      ...prev?.data.info,
                      userType: e.target.value,
                    },
                  },
                }))
              }
              value={state?.data?.info?.userType}
              options={[
                {
                  label: "User",
                  value: "dealership-member",
                },
                ...(user?.data?.info?.userType !== "dealership-member"
                  ? [
                      {
                        label: "Admin",
                        value: "dealership-admin",
                      },
                      {
                        label: "Owner",
                        value: "owner",
                      },
                    ]
                  : []),
              ]}
            />
          </Grid>

          {state?.data?.info?.registrationLink ? (
            <Grid xs={4}>
              <Button type="button" variant="blue" onClick={handleResendLink}>
                Send Registration Link
              </Button>
            </Grid>
          ) : null}

          <Grid xs={12} container>
            <Grid xs={12}>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                Notifications
              </p>
            </Grid>

            <Grid xs={4}>
              <TextField
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    data: {
                      ...prev?.data,
                      info: {
                        ...prev?.data.info,
                        notificationsDuration:
                          Math.abs(Number(e.target.value)) || undefined,
                      },
                    },
                  }))
                }
                placeholder="Notification Duration (seconds)"
                value={state.data.info.notificationsDuration}
                name="Notification Duration (seconds)"
                id="notifications-duration"
                type="number"
              />
            </Grid>
          </Grid>

          <Grid xs={12} container>
            <Grid xs={12}>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                User Permissions
              </p>
            </Grid>
            {(publicRoles?.length || 0) > 0 && (
              <Grid
                xs={12}
                container
                style={{ paddingLeft: "10px", marginBottom: "10px" }}
              >
                <Grid
                  xs={12}
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Role suggestions
                  </div>
                  <Tooltip
                    tooltipStyle={{
                      background: "#fff",
                      color: "#000",
                      padding: "10px",
                      border: "1px solid #8080804a",
                      fontWeight: "bold",
                    }}
                    content="Click on the suggested roles to add them. You can also hover on each of the suggested roles to see information about permissioning."
                  >
                    <IoHelpCircleSharp
                      size={25}
                      style={{ cursor: "pointer" }}
                    />
                  </Tooltip>
                </Grid>
                <Grid
                  xs={12}
                  container
                  spacing={2}
                  style={{
                    paddingLeft: "10px",
                    marginBottom: "5px",
                    marginTop: "10px",
                  }}
                >
                  {(publicRoles ?? [])?.map((pRole) => (
                    <Grid key={pRole._id} xs={2}>
                      <Tooltip
                        tooltipStyle={{
                          background: "#fff",
                          color: "#000",
                          padding: "10px",
                          border: "1px solid #8080804a",
                          fontWeight: "bold",
                        }}
                        content={pRole.data.info.description}
                      >
                        <Button
                          variant="blue"
                          style={{ width: "100%", fontSize: "14px" }}
                          onClick={() => {
                            if (
                              state.data.roles?.find((r) => r._id === pRole._id)
                            )
                              return;
                            setState((prev) => ({
                              ...prev,
                              data: {
                                ...prev?.data,
                                roles: [...(state.data.roles ?? []), pRole],
                              },
                            }));
                          }}
                        >
                          {pRole.data.info.name}
                        </Button>
                      </Tooltip>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}

            <Grid
              container
              xs={6}
              style={{ marginBottom: "20px", paddingLeft: "10px" }}
            >
              <Grid xs={12}>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                  }}
                >
                  Roles
                </div>
              </Grid>
              <Grid xs={12}>
                <MultiSelect<Role>
                  placeholder="Select roles"
                  getOptionSelected={(x, y) => x._id === y._id}
                  options={roles ?? []}
                  required
                  id="role-select"
                  name="role-select"
                  loading={isFetchingRoles}
                  value={state?.data.roles ?? []}
                  onChange={(roles) =>
                    setState((prev) => ({
                      ...prev,
                      data: {
                        ...prev?.data,
                        roles,
                      },
                    }))
                  }
                  getOptionLabel={(role) => role.data.info.name}
                />
              </Grid>
            </Grid>

            <Grid
              container
              xs={6}
              style={{ marginBottom: "20px", padding: "0px 10px" }}
            >
              <Grid xs={12}>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                  }}
                >
                  Teams
                </div>
              </Grid>
              <Grid xs={12}>
                <MultiSelect<Team>
                  placeholder="Select teams"
                  getOptionSelected={(x, y) => x._id === y._id}
                  loading={isFetchingTeams}
                  options={teams ?? []}
                  id="team-select"
                  name="team-select"
                  value={state?.data.teams ?? []}
                  onChange={(teams) =>
                    setState((prev) => ({
                      ...prev,
                      data: {
                        ...prev?.data,
                        teams,
                      },
                    }))
                  }
                  getOptionLabel={(role) => role.data.info.name}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid xs={12}>
            <Button
              style={{ width: "30%" }}
              form={id}
              id={`${type}-user-button`}
              variant="gradient"
              type="submit"
            >
              {type === "create" ? "Create Account" : "Save Changes"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
