import { Fragment, useRef, useState } from "react";
import { IoDocumentTextOutline } from "react-icons/io5";
import IFrameURLModal from "../../PreviewDocuments/IFrameURLModal";

import DateFilter from "@Components/Filters/DateFilter";
import TextFilter from "@Components/Filters/TextFilter";
import {
  DateFilterPreviewProps,
  FilterPreviewProps,
} from "@Components/Filters/types";
import IconButton from "@Components/IconButton";
import { Column } from "@Components/Table/types";

import useCurrentUser from "@Hooks/useCurrentUser";
import { useGetFundingDocuments } from "@Hooks/useDocuments";

import { formatDateAmerican } from "@Utils/functions";
import { hasSectionPermissions } from "@Utils/permissions";

import { Document } from "@Types/documents";

import Grid from "@Components/Grid";
import DocumentsTable from "../DocumentsTable/DocumentsTable";

import { Deal, DealStatus } from "@Types/deal";
import InactiveSection from "../InactiveSection";
import classes from "./FundingDocuments.module.scss";

type FundingDocumentsProps = {
  deal: Deal;
};
const sectionEnabled = (status: DealStatus) =>
  status === DealStatus.SentToDealer ||
  status === DealStatus.SubmittedForFunding ||
  status === DealStatus.FundingHeld ||
  status === DealStatus.Funded;

const FundingDocuments = ({ deal }: FundingDocumentsProps) => {
  const user = useCurrentUser();
  const dropzoneRef = useRef(null);

  const [documentForDialog, setDocumentForDialog] = useState<
    Document | undefined
  >(undefined);
  const columns: Column<Document>[] = [
    {
      label: "Description",
      value: (document) => document.data.info.name,
      hide: !hasSectionPermissions("deal", "funding_documents", user),
      id: "description",
      truncate: 40,
      filters: [
        {
          placeholder: "Description",
          preview: TextFilter,
          queryPath: ["data", "info", "name"],
        },
      ],
    },
    {
      id: "uploadedAt",
      label: "Uploaded at",
      value: (document) => formatDateAmerican(document.createdAt, true),
      filters: [
        {
          placeholder: "Uploaded at",
          preview: (props: FilterPreviewProps) => (
            <DateFilter {...(props as unknown as DateFilterPreviewProps)} />
          ),
          queryPath: ["createdAt"],
        },
      ],
      sortPath: ["createdAt"],
    },

    {
      id: "actions",
      label: "Actions",
      value: (document) => (
        <IconButton
          id={`viewDocument_${document._id}`}
          className={"blueCircle"}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setDocumentForDialog(document);
          }}
        >
          <IoDocumentTextOutline size={18} />
        </IconButton>
      ),
    },
  ];
  return (
    <div className={classes.container}>
      <Grid container xs={12} style={{ height: "100%" }}>
        {sectionEnabled(deal.data.info.status) ? (
          <Grid xs={12}>
            <DocumentsTable<Document>
              dropzoneRef={dropzoneRef}
              tableTitle="Funding Documents"
              uploadDialogTitle="Upload funding document"
              deal={deal}
              queryKey={`funded_documents_${deal._id}`}
              width="half"
              type="funded_documents"
              useDocumentType={"create-funded-documents"}
              columns={columns.filter((x) => !x.hide)}
              useGetFunction={useGetFundingDocuments}
              query={{ "data.dealId": deal._id }}
            />
          </Grid>
        ) : (
          <Fragment>
            <Grid xs={12}>
              <div className={classes.title}>Funding documents</div>
            </Grid>
            <Grid xs={12}>
              <InactiveSection
                node={
                  <>
                    This section is currently inactive. It will become available
                    once the deal reaches the{" "}
                    <strong>&quot;Awaiting signed documents&quot;</strong>{" "}
                    status.
                  </>
                }
              />
            </Grid>
          </Fragment>
        )}

        {documentForDialog && (
          <IFrameURLModal
            fileName={documentForDialog.data.info.name}
            url={documentForDialog.data.info.url}
            closeDialog={() => setDocumentForDialog(undefined)}
          />
        )}
      </Grid>
    </div>
  );
};

export default FundingDocuments;
