import BackButton from "@Components/BackButton";
import Grid from "@Components/Grid";
import Stepper from "@Components/Stepper";
import { getApplication, getApplicationPDF } from "@Http/applications";
import { useQuery } from "@tanstack/react-query";
import { FaPrint } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import Applicant from "./Applicants";
import ChangeRequests from "./ChangeRequests";
import Collateral from "./Collateral";
import FinancialInfo from "./FinancialInfo";
import TradeIn from "./TradeIn";

import IconButton from "@Components/IconButton";
import Tooltip from "@Components/Tooltip";
import { useGetTitleIssue } from "@Hooks/useTitleIssues";
import { Deal, DealStatus } from "@Types/deal";
import { Fragment } from "react";
import styles from "./Application.module.scss";
import DealerNotes from "./DealerNotes";
import DocumentsSection from "./Documents/DocumentsSection/DocumentsSection";
import StatusChange from "./StatusChange/StatusChange";
import Taxes from "./Taxes/Taxes";

const constructApplicationSummary = (deal: Deal) => {
  const applicantNames = [
    deal?.data?.applicant?.data?.info?.firstName,
    deal?.data?.applicant?.data?.info?.lastName,
  ]
    .filter((x) => x)
    .join(" ");
  const coApplicantNames = [
    deal?.data?.coApplicant?.data?.info?.firstName,
    deal?.data?.coApplicant?.data?.info?.lastName,
  ]
    .filter((x) => x)
    .join(" ");
  const customersNames = [applicantNames, coApplicantNames]
    .filter((x) => x)
    .join(" / ");
  const vehicle = [
    deal?.data?.info?.vehicle?.make,
    deal?.data?.info?.vehicle?.model,
    deal?.data?.info?.vehicle?.year,
  ]
    .filter((x) => x)
    .join(" ");
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "20px",
          alignItems: "center",
          whiteSpace: "nowrap",
        }}
      >
        <div style={{ flex: 1 }}>
          <div>{customersNames}</div>
        </div>
        <hr
          style={{
            border: "2px solid #dfdfdf",
            borderRadius: "5px",
            height: "35px",
          }}
        />
        <div style={{ flex: 1, whiteSpace: "nowrap" }}>{vehicle}</div>
        {deal?.data?.info?.vehicle?.VIN && vehicle ? (
          <Fragment>
            <hr
              style={{
                border: "2px solid #dfdfdf",
                borderRadius: "5px",
                height: "35px",
              }}
            />
            <div style={{ whiteSpace: "nowrap" }}>
              VIN: {deal?.data?.info?.vehicle?.VIN}
            </div>
          </Fragment>
        ) : null}
      </div>
    </div>
  );
};

const Application = () => {
  const { id } = useParams<{ id: string }>();
  const {
    data: deal,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["deal", id],
    queryFn: getApplication(id ?? ""),
  });

  const { titleIssue } = useGetTitleIssue(deal?.data?.titleIssueId);

  if (isLoading) return <>Loading...</>;
  if (error) return <>{`${(error as { message: string })?.message}`}</>;
  return deal ? (
    <Grid container xs={12} spacing={1} className={styles.container}>
      <Grid xs={1.5}>
        <BackButton />
      </Grid>
      <Grid xs={12}>
        <div
          style={{
            display: "flex",
            background: "#f3f3f3",
            borderRadius: "8px",
            width: "100%",
          }}
        >
          <span className={styles.applicationSummary}>
            {constructApplicationSummary(deal)}
          </span>
        </div>
        <div className={styles.applicationProgress}>
          <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <h2 className={styles.title}>Application progress</h2>
            <Tooltip
              tooltipStyle={{
                background: "#fff",
                color: "#000",
                padding: "10px",
                border: "1px solid #8080804a",
                fontWeight: "bold",
              }}
              content="If you need a credit application, click this icon to download it."
            >
              <IconButton
                onClick={async () => {
                  await getApplicationPDF(deal)();
                }}
              >
                <FaPrint color="#6b97f6" size={30} />
              </IconButton>
            </Tooltip>
          </div>
          {[
            DealStatus.Countered,
            DealStatus.Approved,
            DealStatus.SentToDealer,
            DealStatus.Dead,
          ].includes(deal?.data?.info?.status) &&
            !deal?.data?.info?.statusReasons?.dealership?.marked && (
              <StatusChange deal={deal} requestedStatus={DealStatus.Dead} />
            )}
        </div>

        <div style={{ marginBottom: "110px" }}>
          <Stepper deal={deal} />
        </div>
      </Grid>
      <Grid xs={12}>
        <DocumentsSection deal={deal} titleIssue={titleIssue} />
      </Grid>

      <Grid xs={4}>
        <ChangeRequests deal={deal} />
      </Grid>
      <Grid xs={8}>
        <DealerNotes deal={deal} />
      </Grid>

      <Grid xs={8}>
        <Applicant type="applicant" deal={deal} />
      </Grid>
      <Grid xs={4}>
        <Applicant type="coApplicant" deal={deal} />
      </Grid>
      <Grid xs={8}>
        <Collateral
          collateral={deal?.data?.info?.vehicle}
          type={deal?.data?.info?.type}
        />
      </Grid>
      <Grid xs={4}>
        <TradeIn
          tradeIn={deal?.data?.info?.price?.trade?.[0]}
          payoff={deal?.data?.info?.price?.payoff}
        />
      </Grid>

      <Grid xs={12}>
        <FinancialInfo deal={deal} />
      </Grid>
      <Grid xs={12}>
        <Taxes deal={deal} />
      </Grid>
    </Grid>
  ) : null;
};

export default Application;
