import { SetStateAction, useState } from "react";

import { useGetDocuments } from "@Hooks/useDocuments";
import { DriverLicenseType } from "@Types/documents";
import IncompleteDeal from "@Types/incomplete_application";

import Grid from "@Components/Grid";
import Radio from "@Components/Radio";
import { Actions } from "../CreateApplication";
import UploadDriverLicense from "../UploadDriverLicense";
import ScanDriverLicense from "./ScanDriverLicense";
import styles from "./styles.module.scss";
import { UploadType } from "@Types/deal";

interface ScanLicenseProps {
  currentStep: number;
  deal: IncompleteDeal;
  setCurrentStep: (v: SetStateAction<number>) => void;
  driverLicenseType: DriverLicenseType;
  setDriverLicenseType: (v: SetStateAction<DriverLicenseType>) => void;
  uploadType: UploadType;
  setUploadType: (v: SetStateAction<UploadType>) => void;
}
const ScanLicense = ({
  deal,
  currentStep,
  uploadType,
  driverLicenseType,
  setDriverLicenseType,
  setUploadType,
  setCurrentStep,
}: ScanLicenseProps) => {
  const [enabled, setEnabled] = useState(true);
  const { data: documents } = useGetDocuments(
    `${deal._id}_${deal.updatedAt}_${driverLicenseType}`,
    {
      query: {
        "data.incompleteDealId": deal._id,
        "data.info.driverLicenseData.applicantType": driverLicenseType,
      },
    },
    enabled
  );

  const uploadeDocuments = documents && (documents ?? []).length > 1;

  return (
    <Grid xs={12} container className={styles.stepContainer}>
      <Grid xs={12} container>
        <Grid xs={12} className={styles.radioContainer}>
          <Radio
            outerLabelStyle={styles.radio}
            id="driverLicenseUpload"
            group="driverLicenseUpload"
            possibleValues={[
              {
                value: "Upload from smartphone",
                label: "Upload from smartphone",
                description:
                  "Upload the driver license using the Web Finance Dealer app on your mobile phone.",
              },
              {
                value: "Upload from computer",
                label: "Upload from computer",
                description:
                  "Upload the driver license from your computer files.",
              },
            ]}
            onChange={(e) => {
              switch (e.target.value) {
                case "Upload from computer":
                case "Upload from smartphone":
                  return setUploadType(e.target.value);

                default:
                  return setUploadType("Upload from smartphone");
              }
            }}
            selectedValue={uploadType}
          />
        </Grid>
        {uploadType === "Upload from computer" ? (
          <UploadDriverLicense
            dealId={deal._id}
            documents={documents}
            setEnabled={setEnabled}
            driverLicenseType={driverLicenseType}
            creditType={deal.data.info.creditType}
            setDriverLicenseType={setDriverLicenseType}
          />
        ) : (
          <ScanDriverLicense dealId={deal._id} />
        )}
      </Grid>

      <Grid xs={12} className={styles.actions}>
        <Actions
          step={currentStep}
          max={5}
          setStep={setCurrentStep}
          nextButtonText={uploadeDocuments ? "Next" : "Skip"}
        />
      </Grid>
    </Grid>
  );
};

export default ScanLicense;
