import { useRef } from "react";

import Button from "@Components/Button";
import {
  useCreateDocument,
  useCreateFundedDocument,
} from "@Hooks/useDocuments";

import Loading from "@Components/Loading";
import useCurrentUser from "@Hooks/useCurrentUser";
import mixpanel from "mixpanel-browser";
import styles from "./DocumentsUploadButton.module.scss";

type DocumentsUploadButtonProps = {
  dashed?: boolean;
  fullWidth?: boolean;
  dealId: string;
  printedDocumentId?: string;
  section:
    | "needed-documents"
    | "required-documents"
    | "driver-license"
    | "title-documents";
  dropZoneHeight?: string;
  useDocument?: "create-documents" | "create-funded-documents";
  handleCreate?: (file: File) => void;
  showArrow?: boolean;
  buttonText?: string;
  refetch?: () => void;
};
const DocumentsUploadButton = ({
  printedDocumentId,
  section,
  dealId,
  handleCreate,
  buttonText = "Upload document",
  useDocument = "create-documents",
  refetch = () => {
    return undefined;
  },
}: DocumentsUploadButtonProps) => {
  const { createDocument, loading } =
    useDocument === "create-documents"
      ? useCreateDocument(refetch)
      : useCreateFundedDocument(refetch);
  const inputFile = useRef<HTMLInputElement | null>(null);
  const user = useCurrentUser();
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files && e?.target?.files?.length) {
      handleCreate
        ? handleCreate(e.target.files[0])
        : handleCreateDocument(e.target.files[0]);
    }
  };

  const handleCreateDocument = (file: File) => {
    createDocument({
      data: {
        incompleteDealId: null,
        dealId,
        ...(printedDocumentId ? { printedDocumentId } : {}),
        info: {
          name: file.name,
        },
      },
      file: file,
    });
    mixpanel.track(
      useDocument === "create-documents"
        ? "Document uploaded before signing"
        : "Signed document uploaded",
      {
        email: user?.data?.info?.email,
        dealershipName: user?.data?.dealership?.data?.info?.name,
      }
    );
  };

  return (
    <Button
      onClick={() => inputFile?.current?.click()}
      className={styles.button}
      style={{ width: "auto", fontSize: 18 }}
      variant="blue"
      disabled={loading}
    >
      {loading ? (
        <Loading
          size={15}
          customTextNode={
            <div style={{ fontSize: 18, color: "white", marginLeft: 10 }}>
              Loading
            </div>
          }
          customLoadingSpinnerStyle={{ top: 0 }}
        />
      ) : (
        buttonText
      )}
      <input
        ref={inputFile}
        type="file"
        id={`${section}-regular-upload-document-input`}
        hidden
        onClick={(e) => {
          (e.target as EventTarget & { value: null }).value = null;
        }}
        onChange={(e) => {
          handleFileChange(e);
        }}
      />
    </Button>
  );
};
export default DocumentsUploadButton;
