import { useState, useEffect, ReactNode } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

const style = {
  display: "flex",
  position: "absolute",
  right: "0px",
  bottom: "0px",
  left: "0px",
  top: "0px",
  zIndex: 1200,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "rgba(240,248,255,0.5)",
} as React.CSSProperties;
interface Props {
  containerRef: React.RefObject<HTMLDivElement>;
  content?: ReactNode;
  customZoneStyle?: React.CSSProperties;
  createDocument: (file: File) => void;
}

export default function DropZone({
  containerRef,
  customZoneStyle,
  content,
  createDocument,
}: Props): JSX.Element {
  const fileSizeLimit = 2147483648; //2GB
  const [show, setShow] = useState(false);
  useEffect(() => {
    const container = containerRef?.current;
    if (container !== null) {
      let lastTarget: EventTarget | null = null;
      container.addEventListener("dragenter", function (e) {
        if (e?.dataTransfer?.items?.[0]?.kind === "file") {
          lastTarget = e.target;
        }
        setShow(true);
      });
      container.addEventListener("dragleave", function (e) {
        if (e.target === lastTarget || e.target === document) {
          setShow(false);
        }
      });
    }
  }, [containerRef, setShow]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (droppedfile) => {
      if (droppedfile.length > 1) {
        toast.error("Please upload only one file at once!", {
          position: "top-center",
        });
        setShow(false);
        return;
      }
      if (droppedfile[0].size < fileSizeLimit) {
        createDocument(droppedfile[0]);
      } else {
        toast.error("Invalid file. Please try again.", {
          position: "top-center",
        });
      }
      setShow(false);
    },
  });

  return (
    <section>
      {show ? (
        <div
          {...getRootProps({
            style: { ...style, ...(customZoneStyle ?? {}) },
          })}
          id="drop"
        >
          {content}
          <input
            data-testid="upload_input"
            {...getInputProps()}
            id="upload_input"
            draggable="true"
          />
        </div>
      ) : null}
    </section>
  );
}
