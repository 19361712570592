import { ReactNode } from "react";
import { IoIosInformationCircle } from "react-icons/io";

type InactiveSectionProps = {
  node: ReactNode;
  showInfo?: boolean;
  containerStyle?: React.CSSProperties;
};

const InactiveSection = ({
  node,
  containerStyle,
  showInfo = true,
}: InactiveSectionProps) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "15px",
        backgroundColor: "#f3f3f3",
        borderRadius: "8px",
        gap: 5,
        ...(containerStyle ?? {}),
      }}
    >
      {showInfo && (
        <div>
          <IoIosInformationCircle
            style={{
              color: "#6b97f6",
              fontSize: "30px",
            }}
          />
        </div>
      )}
      <div
        style={{
          textAlign: "center",
          color: "#757575",
          fontSize: "18px",
          lineHeight: "22px",
        }}
      >
        {node}
      </div>
    </div>
  );
};

export default InactiveSection;
