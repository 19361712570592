import { CSSProperties, ReactNode, useState } from "react";

import styles from "./Tooltip.module.scss";

type TooltipProps = {
  children: ReactNode;
  content: ReactNode;
  tooltipStyle?: CSSProperties;
};
const Tooltip = ({ children, content, tooltipStyle }: TooltipProps) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {children}

      {isTooltipVisible && (
        <div className={`${styles.tooltip}`} style={tooltipStyle}>
          {content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
