import { MutableRefObject, ReactNode } from "react";
import styles from "./Paper.module.scss";
type Props = {
  id?: string;
  title?: string | JSX.Element;
  style?: React.CSSProperties;
  outerClassName?: string;
  children: ReactNode;
  tabIndex?: number;
  ref?: MutableRefObject<null>;
};

const Paper = ({
  title,
  children,
  style,
  id,
  outerClassName,
  tabIndex,
  ref,
}: Props) => {
  return (
    <div
      ref={ref}
      tabIndex={tabIndex || 0}
      id={id}
      className={`${styles.root} ${styles?.[outerClassName ?? ""] ?? ""}`}
      style={style}
    >
      {typeof title === "string" ? (
        <h2 className={styles.title}>{title}</h2>
      ) : (
        title
      )}
      {children}
    </div>
  );
};

export default Paper;
