import { useNavigate } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";

import { Role } from "@Types/roles";
import Table from "@Components/Table";
import useGetRoles from "@Hooks/useRoles";
import AddButton from "@Components/AddButton";
import IconButton from "@Components/IconButton";
import { Column } from "@Components/Table/types";

import styles from "./Roles.module.scss";

const Roles = () => {
  const columns: Column<Role>[] = [
    {
      id: "name",
      label: "Name",
      value: (team) => team.data.info.name,
    },

    {
      id: "actions",
      label: "Actions",
      value: (role) =>
        !role?.data?.isPublic ? (
          <IconButton
            id={`open-role-${role?._id}`}
            className={"blueCircle"}
            onClick={() => {
              navigate(`/role/${role._id}`);
            }}
          >
            <AiOutlineArrowRight size={18} />
          </IconButton>
        ) : (
          <></>
        ),
    },
  ];

  const navigate = useNavigate();

  return (
    <div className={styles.tableContainer}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
        }}
      >
        <h2 className={styles.title}>Roles</h2>

        <AddButton entity="role" id="create-new-role" />
      </div>
      <Table<Role>
        columns={columns}
        disabledRow={(role: Role) => Boolean(role.data.isPublic)}
        useGetFunction={useGetRoles}
        entityType="roles"
      />
    </div>
  );
};

export default Roles;
