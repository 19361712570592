import { useState } from "react";
import { IoDocumentTextOutline } from "react-icons/io5";
import IFrameURLModal from "../../PreviewDocuments/IFrameURLModal";

import DateFilter from "@Components/Filters/DateFilter";
import TextFilter from "@Components/Filters/TextFilter";
import {
  DateFilterPreviewProps,
  FilterPreviewProps,
} from "@Components/Filters/types";
import IconButton from "@Components/IconButton";

import useCurrentUser from "@Hooks/useCurrentUser";

import { capitalize, formatDateAmerican } from "@Utils/functions";
import { hasSectionPermissions } from "@Utils/permissions";

import Grid from "@Components/Grid";

import SelectFilter from "@Components/Filters/SelectFilter";
import { useGetStipulations } from "@Hooks/useStipulations";
import { Deal } from "@Types/deal";
import { Stipulation, StipulationStatus } from "@Types/stipulations";
import UploadStipulations from ".";
import { queryClient } from "../../../../../../App";
import { Column } from "../DocumentsTable/types";
import DeleteStipulation from "./DeleteStipulation/DeleteStipulation";
import classes from "./Stipulations.module.scss";
import StipulationsTable from "./StipulationsTable/StipulationsTable";

type StipulationsProps = {
  deal: Deal;
};

const Stipulations = ({ deal }: StipulationsProps) => {
  const user = useCurrentUser();
  const queryKey = `stipulations_${deal._id}`;
  const [stipulationForDialog, setStipulationForDialog] = useState<
    Stipulation | undefined
  >(undefined);
  const refetch = async () => {
    await queryClient.refetchQueries(["stipulations", queryKey]);
  };
  const columns: Column<Stipulation>[] = [
    {
      label: "Description",
      value: (stipulation) => stipulation.data.info.description,
      hide: !hasSectionPermissions("deal", "stipulations", user),
      id: "description",
      truncate: 40,
      filters: [
        {
          placeholder: "Description",
          preview: TextFilter,
          queryPath: ["data", "info", "description"],
        },
      ],
    },
    {
      id: "status",
      label: "Status",

      value: (stipulation) => {
        let color = "#f3f3f3";
        switch (stipulation.data.info.status) {
          case StipulationStatus.PENDING:
          case StipulationStatus.AWAITING_UPLOAD:
            color = "#ffcc00";
            break;
          case StipulationStatus.REJECTED:
            color = "#cc3300";
            break;
          case StipulationStatus.APPROVED:
            color = "#339900";
            break;
        }
        return (
          <span
            style={{
              background: color,
              fontWeight: 600,
              padding: "5px",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            {capitalize(
              stipulation.data.info.status === StipulationStatus.PENDING
                ? "Pending approval"
                : stipulation.data.info.status
            ) ?? "N/A"}
          </span>
        );
      },
      filters: [
        {
          placeholder: "Status",
          preview: SelectFilter,
          queryPath: ["data", "info", "status"],
          valuesForSelect: Object.values(StipulationStatus).map((x) => ({
            label: capitalize(x) ?? x,
            value: x,
          })),
        },
      ],
      sortPath: ["data", "info", "status"],
    },
    {
      id: "canBeUploadedBy",
      label: "Can be uploaded by",
      value: (stipulation) =>
        stipulation.data?.info?.canBeUploadedBy
          ?.map((x) => (x === "coApplicant" ? "Co-Applicant" : capitalize(x)))
          ?.join(" / "),
      filters: [
        {
          placeholder: "Can be uploaded by",
          preview: SelectFilter,
          queryPath: ["data", "info", "canBeUploadedBy"],
          valuesForSelect: ["applicant", "coApplicant", "dealer"].map((x) => ({
            label: x === "coApplicant" ? "Co-Applicant" : capitalize(x) ?? x,
            value: x,
          })),
        },
      ],
      sortPath: ["data", "info", "canBeUploadedBy"],
    },
    {
      id: "uploadedBy",
      label: "Uploaded by",
      value: (stipulation) =>
        capitalize(stipulation.data.info.uploadedBy) ?? "N/A",
      filters: [
        {
          placeholder: "Uploaded by",
          preview: SelectFilter,
          queryPath: ["data", "info", "uploadedBy"],
          valuesForSelect: ["applicant", "coApplicant", "dealer"].map((x) => ({
            label: capitalize(x) ?? x,
            value: x,
          })),
        },
      ],
      sortPath: ["data", "info", "uploadedBy"],
    },
    {
      id: "uploadedAt",
      label: "Uploaded at",
      value: (stipulation) =>
        formatDateAmerican(stipulation.data.info.uploadedAt, true) || "N/A",
      filters: [
        {
          placeholder: "Uploaded at",
          preview: (props: FilterPreviewProps) => (
            <DateFilter {...(props as unknown as DateFilterPreviewProps)} />
          ),
          queryPath: ["data", "info", "uploadedAt"],
        },
      ],
      sortPath: ["data", "info", "uploadedAt"],
    },

    {
      id: "actions",
      label: "Actions",
      valueOptionsEnabled: true,
      value: (stipulation, options) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            {stipulation?.data?.info?.status !== StipulationStatus.APPROVED &&
              stipulation?.data?.info?.canBeUploadedBy?.includes("dealer") && (
                <div
                  style={{
                    marginLeft: stipulation.data.info.url ? "-35px" : "-15px",
                  }}
                >
                  <UploadStipulations
                    refetch={refetch}
                    stipulation={stipulation}
                  />
                </div>
              )}
            {stipulation.data.info.url && (
              <IconButton
                id={`viewDocument_${stipulation._id}`}
                className={"blueCircle"}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setStipulationForDialog(stipulation);
                }}
              >
                <IoDocumentTextOutline size={18} />
              </IconButton>
            )}
            <DeleteStipulation
              stipulation={stipulation}
              refetch={options?.refetch}
            />
          </div>
        );
      },
    },
  ];
  return (
    <div id="uploadStipulations" className={classes.container}>
      <Grid container xs={12} style={{ display: "flex", alignItems: "center" }}>
        <Grid xs={12}>
          <StipulationsTable
            tableTitle="Stipulations"
            deal={deal}
            columns={columns.filter((x) => !x.hide)}
            queryKey={queryKey}
            useGetFunction={useGetStipulations}
            query={{
              "data.dealId": deal._id,
              "data.info.canBeUploadedBy": "dealer",
              $and: [
                {
                  "data.info.description": { $exists: true },
                },
                {
                  "data.info.description": { $ne: null },
                },
                {
                  "data.info.description": { $ne: "" },
                },
              ],
            }}
          />
        </Grid>

        {stipulationForDialog && stipulationForDialog.data.info.url && (
          <IFrameURLModal
            fileName={`${stipulationForDialog.data.info.description}.pdf`}
            url={stipulationForDialog.data.info.url}
            closeDialog={() => setStipulationForDialog(undefined)}
          />
        )}
      </Grid>
    </div>
  );
};

export default Stipulations;
