import { useParams } from "react-router-dom";

import BackButton from "@Components/BackButton";
import Grid from "@Components/Grid";
import Loading from "@Components/Loading";
import Paper from "@Components/Paper";
import { useGetTitleIssue } from "@Hooks/useTitleIssues";

import Dropzone from "@Components/Dropzone";
import DocumentsUploadButton from "@Pages/Applications/Application/Documents/DocumentsUploadButton";
import { useRef, useState } from "react";
import Overview from "./Overview";
import TitleIssueDocumentsDialog from "./TitleIssueDocumentsDialog";
import styles from "./TitleIssues.module.scss";
import TitleIssuesNotes from "./TitleIssuesNotes";
import TitleIssueStepper from "./TitleIssueStepper";
import UploadTitleIssueDocument from "./UploadTitleIssueDocument";

export default function TitleIssue() {
  const { id } = useParams<{ id: string }>();
  const { titleIssue } = useGetTitleIssue(id as string);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | undefined>();
  const [uploadWithDropzone, setUploadWithDropzone] = useState(false);
  const dropzoneRef = useRef(null);

  const handleOpenDialog = (newFile: File) => {
    setOpen(true);
    setFile(newFile);
  };
  if (!id) return <>Error</>;
  if (!titleIssue) return <Loading />;

  return (
    <Grid className={styles.container} xs={12} container spacing={1}>
      <Grid xs={12}>
        <BackButton />
      </Grid>
      <Grid xs={8}>
        <h2 className={styles.title}>Title Info</h2>
        <Grid
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Grid xs={11}>
            <TitleIssueStepper
              oldTitleStatus={
                titleIssue.data.info.oldTitleRegistrationInfo.status
              }
              newTitleStatus={titleIssue.data.info.titleRegistrationInfo.status}
              receiptStatus={titleIssue.data.info.receiptInfo.status}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={4}>
        <Paper
          title="Overview"
          style={{ height: "calc(100% - 34px)", boxSizing: "border-box" }}
        >
          <Overview titleIssue={titleIssue} />
        </Paper>
      </Grid>

      <Grid xs={8} ref={dropzoneRef} style={{ position: "relative" }}>
        <Dropzone
          createDocument={(file) => {
            handleOpenDialog(file);
            setUploadWithDropzone(true);
          }}
          content={
            <div
              style={{
                fontWeight: 600,
                color: "#5e5d65",
                fontSize: "20px",
              }}
            >
              Drop files here to upload
            </div>
          }
          customZoneStyle={{
            border: "3px dashed #6b97f6",
            borderRadius: "5px",
          }}
          containerRef={dropzoneRef}
        />
        {file && loading && uploadWithDropzone && (
          <div
            style={{
              display: "flex",
              position: "absolute",
              right: "0px",
              bottom: "0px",
              left: "0px",
              top: "0px",
              zIndex: 1200,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(240,248,255,0.5)",
              border: "3px dashed #6b97f6",
              borderRadius: "5px",
            }}
          >
            <Loading
              size={25}
              customTextNode={
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    color: "#5e5d65",
                    paddingLeft: "5px",
                    display: "inline-block",
                    paddingTop: "4px",
                  }}
                >
                  Uploading...
                </div>
              }
            />
          </div>
        )}
        <Paper
          style={{ height: "calc(100% - 34px)" }}
          title={
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <div className={styles.title}>Upload title documents</div>
                <div className={styles.tableTitleSecondary}>
                  (You can drag files in this section to upload)
                </div>
              </div>

              <div>
                {loading ? (
                  <Grid
                    xs={6}
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                  >
                    <Loading
                      size={25}
                      customTextNode={
                        <div
                          style={{
                            fontSize: "20px",
                            fontWeight: 600,
                            color: "#5e5d65",
                            paddingLeft: "5px",
                            display: "inline-block",
                            paddingTop: "4px",
                          }}
                        >
                          Uploading...
                        </div>
                      }
                    />
                  </Grid>
                ) : (
                  <DocumentsUploadButton
                    dealId=""
                    section={"title-documents"}
                    dashed
                    handleCreate={handleOpenDialog}
                  />
                )}
              </div>
            </div>
          }
        >
          <UploadTitleIssueDocument titleIssue={titleIssue} />
        </Paper>
      </Grid>

      <Grid xs={4} className={styles.notes}>
        <Paper title="Title Notes" style={{ height: "calc(100% - 34px)" }}>
          <TitleIssuesNotes
            titleIssueId={titleIssue._id}
            notes={titleIssue.data.titleIssueNotes ?? []}
          />
        </Paper>
      </Grid>
      {titleIssue && (
        <TitleIssueDocumentsDialog
          file={file}
          openDialog={open}
          setUploadWithDropzone={setUploadWithDropzone}
          setOpenDialog={setOpen}
          setLoading={setLoading}
          titleIssue={titleIssue}
        />
      )}
    </Grid>
  );
}
