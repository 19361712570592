import { useState } from "react";

import Filter from "@Components/Filters/Filter";
import { transformFiltersToQuery } from "@Components/Filters/filterUtills";
import { Filters } from "@Components/Filters/types";
import LoadingFullScreen from "@Components/Loading/LoadingFullScreen";

import Sort from "./Sort";
import { transformSort } from "./Sort/Sort";
import SortBy from "./Sort/types";

import { Applicant } from "@Types/applicants";
import { Deal } from "@Types/deal";
import IncompleteDeal from "@Types/incomplete_application";
import { User } from "@Types/users";
import { TableProps } from "./types";

import { Chargeback } from "@Types/chargebacks";
import { Prescreen } from "@Types/prescreens";
import { Role } from "@Types/roles";
import { Team } from "@Types/teams";
import { TitleIssue } from "@Types/title_issues";
import { useNavigate } from "react-router-dom";
import styles from "./Table.module.scss";

export function truncate(
  input: string | undefined,
  length: number
): string | undefined {
  if (input && input?.length > length + 3) {
    return input.substring(0, length) + "...";
  }
  return input;
}

const Table = <
  T extends
    | Deal
    | User
    | Applicant
    | IncompleteDeal
    | TitleIssue
    | Role
    | Team
    | Chargeback
    | Prescreen
>({
  columns,
  entityType,
  disabledRow,
  noEntriesMessage = "No entries found!",
  useGetFunction,
  query,
  projection,
  showTotalsFooter = false,
}: TableProps<T>) => {
  const [filters, setFilters] = useState<Filters | undefined>(undefined);
  const [sort, setSort] = useState<SortBy | undefined>();
  const [hoverRow, setHoverRow] = useState<number | undefined>();

  const navigate = useNavigate();
  const { data: entries, isLoading } = useGetFunction({
    query: {
      ...(filters ? transformFiltersToQuery(filters) : {}),
      ...(query ?? {}),
    },
    options: {
      limit: 100,
      ...(sort ? transformSort(sort) : { sort: { createdAt: "desc" } }),
      ...(projection ? { projection } : {}),
    },
  });

  return (
    <table cellSpacing={0} cellPadding={0} border={0} className={styles.table}>
      <thead className={styles.tableHead}>
        <tr>
          {columns.map((column, index) => (
            <th key={index} className={styles.columnHeader}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "5px",
                }}
              >
                {column.label}
                {column.sortPath && (
                  <Sort sort={sort} path={column.sortPath} setSort={setSort} />
                )}
                {column?.filters && (
                  <Filter
                    id={column.id}
                    columnFilters={column.filters}
                    filterState={filters?.[column.id]}
                    setFilterState={(newFilterState) => {
                      setFilters((prevFilters) => ({
                        ...prevFilters,
                        [column.id]: newFilterState,
                      }));
                    }}
                  />
                )}
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {entries?.length ? (
          entries.map((entry, index) => {
            return (
              <tr
                key={index}
                className={styles.row}
                onMouseEnter={() => {
                  setHoverRow(index);
                }}
                onMouseLeave={() => {
                  setHoverRow(undefined);
                }}
                onClick={() =>
                  disabledRow?.(entry)
                    ? null
                    : "_id" in entry &&
                      entityType &&
                      location.pathname !== `/${entityType}/${entry._id}` &&
                      navigate(`/${entityType}/${entry._id}`)
                }
              >
                {columns.map((column, columnIndex) => {
                  const style = column?.style ? column?.style(entry) : {};
                  const value = column.value(entry);
                  return (
                    <td
                      key={columnIndex}
                      className={`${styles.column} ${
                        column?.id === "actions" ? styles.actions : ""
                      }`}
                    >
                      <div
                        className={styles.columnContainer}
                        style={
                          column?.id === "actions"
                            ? {
                                ...style,
                                paddingLeft: "15px",
                              }
                            : column?.id === "delete"
                            ? {
                                ...style,
                                paddingLeft: "10px",
                              }
                            : style
                        }
                      >
                        {column.hoverIcon && index === hoverRow
                          ? typeof column.hoverIcon === "function"
                            ? column.hoverIcon(entry)
                            : column.hoverIcon
                          : typeof column.icon === "function"
                          ? column.icon(entry)
                          : column.icon}{" "}
                        {column.truncate && typeof value === "string"
                          ? truncate(value, column.truncate)
                          : column.value(entry)}
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })
        ) : isLoading ? (
          <tr className={styles.notFound}>
            <td colSpan={columns.length ?? 1}>
              <LoadingFullScreen />
            </td>
          </tr>
        ) : (
          <tr className={styles.notFound}>
            <td colSpan={columns.length ?? 1}>{noEntriesMessage}</td>
          </tr>
        )}
      </tbody>
      {showTotalsFooter && (
        <tfoot>
          <tr className={styles.footerRow}>
            {columns.map((column, index) => {
              if (column?.total) {
                return (
                  <td className={styles.column} key={index}>
                    <div className={styles.columnContainer}>
                      <span style={{ fontWeight: "bold" }}>
                        Total: {column?.total(entries || [])}
                      </span>
                    </div>
                  </td>
                );
              }
              return <td className={styles.column} key={index}></td>;
            })}
          </tr>
        </tfoot>
      )}
    </table>
  );
};
export default Table;
