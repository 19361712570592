import { useState } from "react";

import Filter from "@Components/Filters/Filter";
import { transformFiltersToQuery } from "@Components/Filters/filterUtills";
import { Filters } from "@Components/Filters/types";

import styles from "./StipulationsTable.module.scss";

import Button from "@Components/Button";
import Grid from "@Components/Grid";
import Loading from "@Components/Loading";
import Sort, { transformSort } from "@Components/Table/Sort/Sort";
import SortBy from "@Components/Table/Sort/types";
import Tooltip from "@Components/Tooltip";

import { Deal } from "@Types/deal";
import { HttpProjection, Query } from "@Types/http";
import { Stipulation } from "@Types/stipulations";
import { IoHelpCircleOutline, IoWarning } from "react-icons/io5";
import { toast } from "react-toastify";
import { queryClient } from "../../../../../../../App";
import { Column, GetFunction } from "../../DocumentsTable/types";
import InactiveSection from "../../InactiveSection";
import CreateStipulationDialog from "../CreateStipulation/CreateStipulationDialog";
export type TableProps = {
  // entries: T[];
  deal: Deal;
  queryKey: string;
  query?: { [maybeAnd: string]: Query | string };
  projection?: HttpProjection;
  tableTitle: string;
  columns: Column<Stipulation>[];
  noEntriesMessage?: string;
  useGetFunction: GetFunction<Stipulation>;
};
export function truncate(
  input: string | undefined,
  length: number
): string | undefined {
  if (input && input?.length > length + 3) {
    return input.substring(0, length) + "...";
  }
  return input;
}

const getWidgetURL = (
  dealId: string,
  applicantPath: "applicant" | "coApplicant"
) => {
  return `${process.env.REACT_APP_WIDGET_STIPULATION_URL}/${dealId}/${applicantPath}`;
};

const StipulationsTable = ({
  columns,
  useGetFunction,
  deal,
  query,
  tableTitle,
  queryKey,
  projection,
}: TableProps) => {
  const [filters, setFilters] = useState<Filters | undefined>(undefined);
  const [sort, setSort] = useState<SortBy | undefined>();
  const [hoverRow, setHoverRow] = useState<number | undefined>();
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const hasCoApplicant = deal?.data?.coApplicant?._id !== undefined;

  const refetch = async () => {
    await queryClient.refetchQueries(["stipulations", queryKey]);
  };
  const { data: entries, isLoading } = useGetFunction(queryKey, {
    query: {
      ...(filters ? transformFiltersToQuery(filters) : {}),
      ...(query ?? {}),
    },
    options: {
      limit: 100,
      ...(sort
        ? transformSort(sort)
        : { sort: { createdAt: "desc" as const } }),
      ...(projection ? { projection } : {}),
    },
  });
  const showApplicantLinks = () => {
    const container = document.getElementById("applicantLinks");
    if (container) {
      const handleClickOutside = (event: MouseEvent) => {
        if (!container.contains(event.target as Node)) {
          toast.dismiss("applicantLinks");
          container.removeEventListener("click", handleClickOutside);
        }
      };
      container.addEventListener("click", handleClickOutside);
    }
    document.addEventListener("click", (event) => {
      const toastElement = document.getElementById("applicantLinks");
      if (toastElement && !toastElement.contains(event.target as Node)) {
        toast.dismiss("applicantLinks");
      }
    });
    toast.info(
      <div className="toastify" style={{ width: "auto" }}>
        <p>Applicant: {getWidgetURL(deal._id, "applicant")}</p>
        {hasCoApplicant && (
          <p>Co-Applicant: {getWidgetURL(deal._id, "coApplicant")}</p>
        )}
      </div>,
      {
        toastId: "applicantLinks",
        autoClose: false,
        closeOnClick: false,
        draggable: false,
      }
    );
  };
  return (
    <Grid container xs={12} style={{ display: "flex", alignItems: "center" }}>
      <Grid xs={6}>
        <div
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
            whiteSpace: "nowrap",
          }}
        >
          <div className={styles.tableTitle}>{tableTitle}</div>
          {entries?.length ? (
            <Tooltip
              content={
                "Please upload any rejected or pending stipulations. Failure to do so will prevent the application from being submitted for funding."
              }
              tooltipStyle={{
                backgroundColor: "#ffcc00",
                padding: 10,
                fontWeight: 500,
                fontSize: 14,
                width: "200px",
                whiteSpace: "normal",
              }}
            >
              <IoWarning cursor={"pointer"} color="#ffcc00" size={25} />
            </Tooltip>
          ) : (
            <Tooltip
              content={"This section is for uploading stipulations."}
              tooltipStyle={{
                backgroundColor: "#b2cbff",
                padding: 10,
                fontWeight: 500,
                fontSize: 14,
                width: "200px",
                whiteSpace: "normal",
              }}
            >
              <IoHelpCircleOutline
                cursor={"pointer"}
                color="#6b97f6"
                size={25}
              />
            </Tooltip>
          )}
        </div>
      </Grid>
      <Grid xs={6}>
        <div
          style={{ display: "flex", gap: "5px", justifyContent: "flex-end" }}
        >
          <>
            {entries?.length ? (
              <Button
                variant="blue"
                style={{
                  whiteSpace: "nowrap",
                  width: "auto",
                  background: "#c7c6cb",
                  fontSize: "18px",
                  color: "#000",
                  fontWeight: 400,
                }}
                onClick={() => {
                  showApplicantLinks();
                }}
              >
                Show link for applicants
              </Button>
            ) : null}
            <Button
              variant="blue"
              style={{
                whiteSpace: "nowrap",
                width: "auto",
                fontSize: "18px",
              }}
              onClick={() => {
                setUploadDialogOpen(true);
              }}
            >
              Create stipulation
            </Button>
          </>
        </div>
      </Grid>
      <CreateStipulationDialog
        refetch={refetch}
        deal={deal}
        dealStipulations={entries ?? []}
        openCreateDialog={uploadDialogOpen}
        setOpenCreateDialog={setUploadDialogOpen}
      />

      <Grid xs={12} style={{ maxHeight: "300px", overflowY: "auto" }}>
        {isLoading ? (
          <InactiveSection
            containerStyle={{ minHeight: "40px" }}
            showInfo={false}
            node={
              <Loading
                size={25}
                customTextNode={
                  <div
                    style={{
                      fontSize: "18px",
                      paddingLeft: "5px",
                      display: "inline-block",
                      paddingTop: "4px",
                    }}
                  >
                    Loading...
                  </div>
                }
              />
            }
          />
        ) : entries?.length ? (
          <table
            cellSpacing={0}
            cellPadding={0}
            border={0}
            className={styles.table}
          >
            <thead className={styles.tableHead}>
              <tr>
                {columns.map((column, index) => (
                  <th key={index} className={styles.columnHeader}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        gap: "5px",
                      }}
                    >
                      {column.label}
                      {column.sortPath && (
                        <Sort
                          sort={sort}
                          path={column.sortPath}
                          setSort={setSort}
                        />
                      )}
                      {column?.filters && (
                        <Filter
                          id={column.id}
                          columnFilters={column.filters}
                          filterState={filters?.[column.id]}
                          setFilterState={(newFilterState) => {
                            setFilters((prevFilters) => ({
                              ...prevFilters,
                              [column.id]: newFilterState,
                            }));
                          }}
                        />
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {entries.map((entry, index) => {
                return (
                  <tr
                    key={index}
                    className={styles.row}
                    onMouseEnter={() => {
                      setHoverRow(index);
                    }}
                    onMouseLeave={() => {
                      setHoverRow(undefined);
                    }}
                  >
                    {columns.map((column, columnIndex) => {
                      const style = column?.style ? column?.style(entry) : {};
                      const value = column.value(
                        entry,
                        column?.valueOptionsEnabled ? { refetch } : undefined
                      );
                      return (
                        <td key={columnIndex} className={styles.column}>
                          <div
                            className={styles.columnContainer}
                            style={
                              column?.id === "actions"
                                ? {
                                    ...style,
                                    paddingLeft: "10px",
                                  }
                                : column?.id === "delete"
                                ? {
                                    ...style,
                                    paddingLeft: "10px",
                                  }
                                : style
                            }
                          >
                            {column.hoverIcon && index === hoverRow
                              ? typeof column.hoverIcon === "function"
                                ? column.hoverIcon(entry)
                                : column.hoverIcon
                              : typeof column.icon === "function"
                              ? column.icon(entry)
                              : column.icon}
                            <span>
                              {" "}
                              {column.truncate && typeof value === "string"
                                ? truncate(value, column.truncate)
                                : column.value(
                                    entry,
                                    column?.valueOptionsEnabled
                                      ? { refetch }
                                      : undefined
                                  )}
                            </span>
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <InactiveSection
            containerStyle={{ minHeight: "40px" }}
            showInfo={false}
            node={<>No entries</>}
          />
        )}
      </Grid>
    </Grid>
  );
};
export default StipulationsTable;
