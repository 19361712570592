import Grid from "@mui/system/Unstable_Grid";
import React from "react";
import { FaInfoCircle } from "react-icons/fa";
import { IoIosWarning } from "react-icons/io";

import useCurrentUser from "@Hooks/useCurrentUser";

import { useNavigate } from "react-router-dom";
import styles from "./TitleIssues.module.scss";

export default function Limits({ showText }: { showText?: boolean }) {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const titleManagement =
    currentUser?.data?.dealership?.data?.info?.titleManagement;

  if (!titleManagement) return null;
  const { outstandingDeals, outstandingTitlesFundingLimit } = titleManagement;
  if (!outstandingTitlesFundingLimit) return null;
  const fundingSuspended =
    (outstandingDeals || 0) > outstandingTitlesFundingLimit;

  return (
    <Grid container spacing={1}>
      {showText && (
        <Grid xs={12}>
          <div className={styles.warning}>
            <FaInfoCircle color="#6b97f6" size={50} />
            <div style={{ textAlign: "center" }}>
              To maintain compliance and avoid potential restrictions, please
              <span className={styles.important}>
                {" "}
                upload the missing documents{" "}
              </span>
              related to these outstanding deals as soon as possible.
              <span className={styles.important}>
                {" "}
                Thank you for your prompt attention to this matter.
              </span>
            </div>
          </div>
        </Grid>
      )}
      {(outstandingDeals || 0) > 0 && (
        <Grid xs={12} display="flex">
          <div
            className={`${styles.limitContainer} ${
              fundingSuspended
                ? styles.limitContainerBorderOver
                : styles.limitContainerBorder
            }`}
          >
            <IoIosWarning size={34} />
            <span>
              {`Outstanding deals: ${outstandingDeals} Limit: ${outstandingTitlesFundingLimit}`}
              .{" "}
              {fundingSuspended
                ? "You have exceeded the allowed missing title documents limit. Upload documents now."
                : "Please upload title documents for the listed loans to avoid funding suspension."}{" "}
              {fundingSuspended && !showText && (
                <span
                  style={{ color: "white", cursor: "pointer", fontWeight: 500 }}
                  onClick={() => navigate("/title_info")}
                >
                  Click here for details.
                </span>
              )}
            </span>
            <IoIosWarning size={34} />
          </div>
        </Grid>
      )}
    </Grid>
  );
}
