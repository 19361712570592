import IconButton from "@Components/IconButton";
import LogoIcon from "@Components/LogoIcon";
import { useEffect, useRef, useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";

import TextArea from "@Components/Inputs/TextArea";
import useCurrentUser from "@Hooks/useCurrentUser";
import useGetMessages, {
  useCreateMessage,
  useUpdateMessage,
} from "@Hooks/useMessages";
import { useStickyState } from "@Hooks/useStickyState";
import { Channel } from "@Types/channels";
import { Message } from "@Types/messages";
import mixpanel from "mixpanel-browser";
import { BsCheck } from "react-icons/bs";
import { HiDocumentText, HiOutlinePaperClip } from "react-icons/hi";
import { IoMdSend } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import { v4 as uuidv4 } from "uuid";
import AttachementIcon, {
  AttachmentLoadingIcon,
  AttachmentUploadedIcon,
} from "./AttachementIcon";
import styles from "./Chat.module.scss";

const SEEN_COLOR = "#6b97f6";
const UNSEEN_COLOR = "#5e5d65";

const todayOrDate = (localeDateString: string) => {
  if (localeDateString === new Date().toLocaleDateString()) {
    return "Today";
  } else {
    return localeDateString;
  }
};
type AttachmentProps = {
  setFile: React.Dispatch<React.SetStateAction<File[] | undefined>>;
  AttachmentIcon: JSX.Element;
};
const Attachments = ({ setFile, AttachmentIcon }: AttachmentProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleClick = () => {
    inputRef?.current?.click?.();
  };
  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setFile((files) => [...(files ?? []), file]);
    }
  };
  return (
    <div className={styles.attachment}>
      <IconButton onClick={handleClick}>{AttachmentIcon}</IconButton>
      <input
        ref={inputRef}
        type="file"
        onChange={handleFileUpload}
        style={{ display: "none" }}
      />
    </div>
  );
};
const getMessageDateLabel = (
  prevMessage: Message | undefined,
  currentMessage: Message
) => {
  const currentMessageDate = new Date(
    currentMessage.createdAt
  ).toLocaleDateString();

  if (!prevMessage) {
    return todayOrDate(currentMessageDate);
  }
  const prevMessageDate = new Date(prevMessage.createdAt).toLocaleDateString();
  if (prevMessageDate !== currentMessageDate) {
    return todayOrDate(currentMessageDate);
  }
};
const Messages = ({
  currentUserEmail,
  messages,
  channelMembersEmails,
  fetchingMessages,
}: {
  currentUserEmail?: string;
  messages?: Message[];
  channelMembersEmails?: string[];
  fetchingMessages: boolean;
}) => {
  const { updateMessage } = useUpdateMessage();

  const handleUpdateMessage = () => {
    if (messages && messages?.length && currentUserEmail && !fetchingMessages) {
      const unseenMessage = messages.find(
        (messsage) =>
          !messsage?.data?.info?.seenBy?.includes(currentUserEmail) &&
          messsage?.data?.chatMember?.data?.info?.email !== currentUserEmail
      );

      if (unseenMessage && unseenMessage._id) updateMessage(unseenMessage._id);
    }
  };

  useEffect(() => {
    document
      .getElementById(`message-${messages?.length}`)
      ?.scrollIntoView({ behavior: "smooth", block: "nearest" });
    handleUpdateMessage();
  }, [messages?.length]);

  return (
    <div className={styles.messagesContainer}>
      {currentUserEmail &&
        Array.isArray(messages) &&
        messages.length > 0 &&
        messages.map((message, index) => (
          <div key={index} id={`message-${index + 1}`}>
            <span className={styles.messageLabel}>
              {getMessageDateLabel(messages?.[index - 1], message)}
            </span>
            <div
              className={`${styles.bubble} ${
                message?.data?.chatMember
                  ? message?.data?.chatMember?.data.info.email ===
                    currentUserEmail
                    ? [styles.right, styles.delivered].join(" ")
                    : styles.left
                  : styles.right
              }`}
            >
              <div className={styles.message}>
                {message.data.info.message}

                {message?.data?.info?.attachments &&
                  message?.data?.info?.attachments.length >= 1 && (
                    <div className={styles.messageAttachmentContainer}>
                      {message.data.info.attachments.map(
                        (attachment, attachmentIndex) => {
                          return (
                            <div
                              className={styles.messageAttachment}
                              key={attachmentIndex}
                            >
                              {message?.data?.delivered ? (
                                <div>
                                  <AttachmentUploadedIcon />
                                </div>
                              ) : (
                                <div>
                                  <AttachmentLoadingIcon />
                                </div>
                              )}
                              <a
                                href={attachment?.url || undefined}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {attachment.name}
                              </a>
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}
                <span
                  className={
                    message?.data?.chatMember?.data.info.email ===
                      currentUserEmail ||
                    !message?.data?.chatMember?.data.info.email
                      ? [styles.messageDate, styles.messageDateRight].join(" ")
                      : [styles.messageDate, styles.messageDateLeft].join(" ")
                  }
                >
                  {" "}
                  {new Date(message.createdAt).toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "2-digit",
                    hour12: true,
                  })}
                  <div>
                    {message?.data?.chatMember?.data?.info?.email ===
                      currentUserEmail &&
                      (channelMembersEmails ?? [])?.map(
                        (memberEmail, memberIndex) => (
                          <BsCheck
                            key={memberIndex}
                            size={15}
                            color={
                              message?.data?.info?.seenBy?.includes(memberEmail)
                                ? SEEN_COLOR
                                : UNSEEN_COLOR
                            }
                          />
                        )
                      )}
                  </div>
                </span>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

const OpenedChat = ({
  setOpen,
  selectedChannel,
}: {
  selectedChannel?: Channel;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [, setSelectedChannelId] = useStickyState<string | null>(
    null,
    "channelId"
  );
  const { createMessage } = useCreateMessage();
  const user = useCurrentUser();
  const { data, isFetching } = useGetMessages(
    selectedChannel?._id as string,
    undefined,
    selectedChannel?._id && user?._id ? true : false
  );
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState<File[] | undefined>(undefined);

  const handleAddNewMessage = () => {
    if (
      selectedChannel &&
      user &&
      (message?.trim()?.length > 0 || files?.length)
    ) {
      createMessage({
        createdAt: new Date().toISOString(),
        data: {
          channelId: selectedChannel?._id,
          internalMessageId: uuidv4(),
          files,
          delivered: false,
          info: {
            attachments: (files ?? [])?.map((file) => ({
              name: file?.name,
            })),
            message,
            sender: {
              memberId: user._id,
              email: user.data.info.email,
              type: "dealership_user",
            },
          },
        },
      });
      mixpanel.track("Chat message sent", {
        email: user?.data?.info?.email,
        dealershipName: user?.data?.dealership?.data?.info?.name,
      });
      setMessage("");
      setFiles(undefined);
    }
  };

  const handleRemoveAttachment = (index: number) =>
    setFiles((files) => files?.filter((_, fileIndex) => fileIndex !== index));
  const awayNames = selectedChannel
    ? Object.entries(selectedChannel?.data.info.namesAndStatuses)
        .filter(([, status]) => status === "away")
        .map(([names]) => names)
    : [];

  const hanldeCloseChat = () => {
    setOpen(false);
    setSelectedChannelId(null);
  };
  return (
    <div className={styles.openChat}>
      <div className={styles.openChatHeader}>
        <IconButton outerClassName={styles.backIcon}>
          <AiOutlineArrowLeft size={18} onClick={hanldeCloseChat} />
        </IconButton>
        <LogoIcon color={"#fff"} />
        <p className={styles.selectedChatName}>
          {selectedChannel?.data.info.name}
        </p>
      </div>
      <Messages
        currentUserEmail={user?.data?.info?.email}
        messages={data}
        fetchingMessages={isFetching}
        channelMembersEmails={selectedChannel?.data?.info?.membersEmails}
      />
      {awayNames.length > 0 && (
        <div className={styles.warning}>
          {`${awayNames.join(", ")} ${
            awayNames?.length > 1 ? "are" : "is"
          } offline at the moment! Please send us a message on info@webfinancedirect.com and our
        team will get in touch.`}
        </div>
      )}
      <div className={styles.newMessageContainer}>
        {files && files.length > 0 && (
          <div className={styles.attachments}>
            <Attachments
              AttachmentIcon={<AttachementIcon />}
              setFile={setFiles}
            />
            {files?.map((file, index) => (
              <div key={index} className={styles.file}>
                <HiDocumentText size={50} color="#6B97F6" /> {file.name}
                <div>
                  <IconButton
                    outerClassName={styles.deleteAttachment}
                    onClick={() => handleRemoveAttachment(index)}
                  >
                    <IoCloseSharp size={20} color="#6B97F6" />
                  </IconButton>
                </div>
              </div>
            ))}
          </div>
        )}

        <div
          className={`${styles.newMessage} ${
            files === undefined || !files.length
              ? styles.newMessageTopRadius
              : ""
          }`}
        >
          <TextArea
            onKeyUp={(e) => {
              if (
                e.keyCode === 13 &&
                !e.shiftKey &&
                (message?.trim()?.length > 0 || files?.length)
              ) {
                handleAddNewMessage();
              }
            }}
            iconStyle={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            startIcon={
              <Attachments
                AttachmentIcon={
                  <HiOutlinePaperClip size={28} color="#5E5D65" />
                }
                setFile={setFiles}
              />
            }
            onChange={(e) => setMessage(e.target.value?.toString() ?? "")}
            containerStyle={{ padding: 2 }}
            showPlaceHolderTop={false}
            value={message}
            name="NewMessage"
            placeholder="Write a message..."
          />
          <IconButton
            outerClassName={styles.sendIcon}
            disabled={message.length < 1 && !files}
            onClick={handleAddNewMessage}
          >
            <IoMdSend size={18} />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default OpenedChat;
