import { auth } from "@Firebase/firebase";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { User, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";

import {
  isExceptionalError,
  resolveErrorMessage,
} from "@Components/Error/Error";
import LoadingFullScreen from "@Components/Loading/LoadingFullScreen";
import Router from "./Router";

import { MutationError } from "@Types/index";

import mixpanel from "mixpanel-browser";
import "react-toastify/dist/ReactToastify.css";

export type CurrentUser = User | null | undefined;
export const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    onError: (error) => {
      toast.error(resolveErrorMessage(error as MutationError), {
        position: "top-center",
      });
    },
  }),
  queryCache: new QueryCache({
    onError: (error) => {
      if (isExceptionalError(error as MutationError)) {
        return;
      } else {
        toast.error(resolveErrorMessage(error as MutationError), {
          position: "top-center",
        });
      }
    },
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

window.addEventListener("offline", () =>
  toast.error("Seems like you are offline!", {
    position: "top-center",
  })
);

function App() {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN as string);
  const [currentUser, setCurrentUser] = useState<CurrentUser>(undefined);
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
      }
    });
  }, []);
  if (currentUser === undefined)
    return <LoadingFullScreen text={"Authenticating"} />;
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App" style={{ minWidth: 1800 }}>
        <ToastContainer style={{ width: "auto" }} />
        <Router currentUser={currentUser} />
      </div>
    </QueryClientProvider>
  );
}

export default App;
