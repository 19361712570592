import { useRef, useState } from "react";
import { IoDocumentTextOutline } from "react-icons/io5";
import IFrameURLModal from "../../PreviewDocuments/IFrameURLModal";

import DateFilter from "@Components/Filters/DateFilter";
import TextFilter from "@Components/Filters/TextFilter";
import {
  DateFilterPreviewProps,
  FilterPreviewProps,
} from "@Components/Filters/types";
import IconButton from "@Components/IconButton";
import { Column } from "@Components/Table/types";

import useCurrentUser from "@Hooks/useCurrentUser";
import { useGetDocuments } from "@Hooks/useDocuments";

import { formatDateAmerican } from "@Utils/functions";
import { hasSectionPermissions } from "@Utils/permissions";

import { Document } from "@Types/documents";

import Grid from "@Components/Grid";
import DocumentsTable from "../DocumentsTable/DocumentsTable";

import { Deal } from "@Types/deal";
import classes from "./OtherDocuments.module.scss";

type OtherDocumentsProps = {
  deal: Deal;
};

const OtherDocuments = ({ deal }: OtherDocumentsProps) => {
  const user = useCurrentUser();
  const dropzoneRef = useRef(null);

  const [documentForDialog, setDocumentForDialog] = useState<
    Document | undefined
  >(undefined);
  const columns: Column<Document>[] = [
    {
      label: "Description",
      value: (document) => document.data.info.name,
      hide: !hasSectionPermissions("deal", "other_documents", user),
      id: "description",
      truncate: 40,
      filters: [
        {
          placeholder: "Description",
          preview: TextFilter,
          queryPath: ["data", "info", "name"],
        },
      ],
    },
    {
      id: "uploadedAt",
      label: "Uploaded at",
      value: (document) => formatDateAmerican(document.createdAt, true),
      filters: [
        {
          placeholder: "Uploaded at",
          preview: (props: FilterPreviewProps) => (
            <DateFilter {...(props as unknown as DateFilterPreviewProps)} />
          ),
          queryPath: ["createdAt"],
        },
      ],
      sortPath: ["createdAt"],
    },

    {
      id: "actions",
      label: "Actions",
      value: (document) => (
        <IconButton
          id={`viewDocument_${document._id}`}
          className={"blueCircle"}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setDocumentForDialog(document);
          }}
        >
          <IoDocumentTextOutline size={18} />
        </IconButton>
      ),
    },
  ];
  return (
    <div className={classes.container}>
      <Grid container xs={12} style={{ height: "100%" }}>
        <Grid xs={12}>
          <DocumentsTable<Document>
            tableTitle="Other Documents"
            dropzoneRef={dropzoneRef}
            queryKey={`other_documents_${deal._id}`}
            uploadDialogTitle="Upload other document"
            deal={deal}
            width="half"
            type="other_documents"
            useDocumentType={"create-documents"}
            columns={columns.filter((x) => !x.hide)}
            useGetFunction={useGetDocuments}
            query={{
              "data.dealId": deal._id,
              "data.stipulationId": { $exists: false },
            }}
          />
        </Grid>
        {documentForDialog && (
          <IFrameURLModal
            fileName={documentForDialog.data.info.name}
            url={documentForDialog.data.info.url}
            closeDialog={() => setDocumentForDialog(undefined)}
          />
        )}
      </Grid>
    </div>
  );
};

export default OtherDocuments;
