import getDefaultStipulations from "@Http/default_stipulations";
import { useQuery } from "@tanstack/react-query";
import { DefaultStipulation } from "@Types/default_stipulations";
import { HttpQuery } from "@Types/http";
import { MutationError } from "@Types/index";

const useGetDefaultStipulations = (query?: HttpQuery) => {
  const queryKey = ["default_stipulations", query ?? []];

  const { data, isLoading, error } = useQuery<
    DefaultStipulation[],
    MutationError,
    DefaultStipulation[]
  >({
    queryKey: queryKey,
    queryFn: getDefaultStipulations(query),
  });

  return {
    data,
    isLoading,
    error,
  };
};
export default useGetDefaultStipulations;
