import { useRef } from "react";
import styles from "./MultiSelectOptions.module.scss";
type MultiSelectOptionDefaultProps = {
  loading: boolean;
  isEmpty: boolean;
};
const MultiSelectOptionLoading = ({
  loading,
  isEmpty,
}: MultiSelectOptionDefaultProps) => {
  const ref = useRef<HTMLLIElement>(null);
  return (
    <li ref={ref} className={styles.optionDefault}>
      <span>{loading ? "Loading..." : isEmpty ? "No data" : null}</span>
    </li>
  );
};

export default MultiSelectOptionLoading;
