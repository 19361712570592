import { FiInfo } from "react-icons/fi";
import { IoDocumentOutline, IoEyeOutline } from "react-icons/io5";

import Tooltip from "@Components/Tooltip";
import { TitleDocumentInfo } from "@Types/title_issues";

import { capitalize } from "@Utils/functions";
import styles from "./TitleIssues.module.scss";

const PreviewDocument = ({
  data,
  title,
  tooltip,
  setPreviewUrl,
}: {
  title: string;
  tooltip: string;
  data: TitleDocumentInfo;
  setPreviewUrl: (url: string) => void;
}) => {
  return (
    <tr
      style={{
        background: "#f3f3f3",
      }}
    >
      <td style={{ textAlign: "center" }}>
        <Tooltip content={tooltip} tooltipStyle={tooltipStyle}>
          <FiInfo color="#6b97f6" size={20} />
        </Tooltip>
      </td>
      <td>
        <div>{title}</div>
      </td>
      <td>
        <div
          className={`${styles?.status} ${styles?.[data?.status ?? "pending"]}`}
        >
          {data?.status === "resolved"
            ? "Approved"
            : data?.status === "awaiting"
            ? "Awaiting upload"
            : capitalize(data?.status)}
        </div>
      </td>
      <td style={{ minWidth: "100px" }}>
        {data?.documents?.map((url, index) => (
          <div
            key={index}
            onClick={() => setPreviewUrl(url)}
            style={{
              cursor: "pointer",
              whiteSpace: "nowrap",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IoDocumentOutline
              style={{ marginRight: 10 }}
              size={25}
              color="#6B97F6"
            />
            Document № {index + 1}
            <IoEyeOutline
              style={{ marginLeft: 10 }}
              size={20}
              color="#6B97F6"
            />
          </div>
        ))}
      </td>
    </tr>
  );
};

export default PreviewDocument;
const tooltipStyle = {
  background: "#fff",
  color: "#000",
  padding: "10px",
  border: "1px solid #8080804a",
  fontWeight: "bold",
};
