import React from "react";
import classes from "./DocumentsSection.module.scss";
import OtherDocuments from "./OtherDocuments/OtherDocuments";
import { Deal } from "@Types/deal";
import FundingDocuments from "./FundingDocuments/FundingDocuments";
import Stipulations from "./Stipulations/Stipulations";
import TitleDocuments from "./TitleDocuments/TitleDocuments";
import { TitleIssue } from "@Types/title_issues";

type DocumentsSectionProps = {
  deal: Deal;
  titleIssue?: TitleIssue | undefined;
};

const DocumentsSection = ({ deal, titleIssue }: DocumentsSectionProps) => {
  return (
    <div className={classes.container}>
      <div className={classes.title}>Documents</div>
      <Stipulations deal={deal} />

      <div className={classes.documentsContainer}>
        <OtherDocuments deal={deal} />
        <FundingDocuments deal={deal} />
      </div>
      <TitleDocuments deal={deal} titleIssue={titleIssue} />
    </div>
  );
};

export default DocumentsSection;
